import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MusicElement } from 'src/app/_models/music-element';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-video-template-candidate-track',
  templateUrl: './video-template-candidate-track.component.html',
  styleUrls: ['./video-template-candidate-track.component.scss']
})
export class VideoTemplateCandidateTrackComponent implements OnInit {

  @Input() track: MusicElement;
  @Input() startPoint = 0;

  @ViewChild('progress') progress: ElementRef;
  @ViewChild('full') full: ElementRef;
  @ViewChild('candidateAudio') candidateAudio: ElementRef;

  cdnUrl = environment.cdnUrl;

  constructor() { }

  ngOnInit(): void {
  }

  selectTrack() {

  }

  audioTimeupdate() { }

  playProgressChange(event: MouseEvent) {
    this.progress.nativeElement.setAttribute('style', 'width: ' + (event.offsetX * 100 / this.full.nativeElement.clientWidth) + '%');
    this.candidateAudio.nativeElement.currentTime = this.candidateAudio.nativeElement.duration * (event.offsetX / this.full.nativeElement.clientWidth);
  }

}
