import { Component, Input, OnInit } from '@angular/core';
import { Page } from '../_models/page';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'app-catalog-category-list',
  templateUrl: './catalog-category-list.component.html',
  styleUrls: ['./catalog-category-list.component.scss']
})
export class CatalogCategoryListComponent implements OnInit {

  @Input() categoryId: number;
  catalogCategoryList: Page[] = [];
  catalogCategoryListCount: number[] = [];

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.apiService.custom('data', 'video-category-menu-list', { categoryId: this.categoryId }, 'post').subscribe((list: any[]) => {
      list.every((v: any) => {
        this.catalogCategoryList.push(new Page(v));
        return true;
      });
    });
    // this.catalogCategoryList.every(v => {
    //   this.apiService.custom('data', 'template-count-by-category', { category_id: v.id }, 'post')
    //     .subscribe((count: number) => {
    //       this.catalogCategoryListCount[v.id] = count;
    //     });
    //   return true;
    // });
  }

}
