<app-header></app-header>

<article *ngIf="cdnUrl !== undefined">

    <section class="section-1">
        <img [src]="cdnUrl+page.optionValue('back1')" class="back">
        <div class="wrapper">
            <div class="header" [innerHTML]="safeHeader1"></div>
            <div class="cta"><a [routerLink]="[page.optionValue('link1') + '.']">{{ page.optionValue('text1') }}</a></div>
            <div class='video' *ngIf="page.optionValue('video1') !== ''">
                <a (click)="openVideo(page.optionValue('video1'))" class="video-impulse"><span class="circlephone"
                        style="transform-origin: center;"></span><span class="circle-fill"
                        style="transform-origin: center;"></span><span class="img-circle"
                        style="transform-origin: center;"><span class="img-circleblock"
                            style="transform-origin: center;"
                            [style.backgroundImage]="'url('+cdnUrl+'/images/video-impulse.png'+')'"></span></span></a>
            </div>
        </div>
    </section>

    <section class="section-2" *ngIf="page.optionValue('video2') !== ''">
        <div class="wrapper">
            <div class="header" [innerHTML]="safeHeader2"></div>
            <div class="video">
                <a (click)="openVideo(this.page.optionValue('video2'))" class="video-impulse"><span class="circlephone"
                        style="transform-origin: center;"></span><span class="circle-fill"
                        style="transform-origin: center;"></span><span class="img-circle"
                        style="transform-origin: center;"><span class="img-circleblock"
                            style="transform-origin: center;"
                            [style.backgroundImage]="'url('+cdnUrl+'/images/video-impulse.png'+')'"></span></span><img
                        [src]="cdnUrl + page.optionValue('back2')"></a>
            </div>
        </div>
    </section>

    <section class="section-3">
        <a [routerLink]="page.optionValue('link1')+'.'">{{ page.optionValue('text1') }}</a>
    </section>

    <section class="section-4">
        <div class="wrapper">
            <div class="header" [innerHTML]="safeHeader3"></div>
            <ul class="video-sample-list">
                <li *ngFor="let item of videoSampleList">
                    <span class="video">
                        <a (click)="openVideo(item.video)">
                            <img [src]="cdnUrl + item.cover">
                        </a>
                    </span>
                    <span class="name">
                        {{item.name}}
                    </span>
                    <span class="link">
                        <button (click)="createVideo(item.video_template_id)">Выбрать видеоролик</button>
                    </span>
                </li>
            </ul>
        </div>
    </section>

    <section class="section-5">
        <a href="/catalog/">Посмотреть весь каталог</a>
    </section>

    <section class="section-6">
        <div class="wrapper">
            <div class="header" [innerHTML]="safeHeader4"></div>
            <div class="instruction">
                <span class="icon"><img [src]="imgAr1"></span>
                <span class="text" [innerHTML]="safeText2"></span>
            </div>
            <div *ngIf="videoCreationProcessList.length > 0">
                <ul class="list-nav">
                    <li *ngFor="let item of videoCreationProcessList; index as i">
                        <a [ngClass]="{'active': currentProcessItem === i}" (click)="currentProcessItem = i">{{item.name}}</a>
                    </li>
                </ul>
                <ng-template ngFor let-item [ngForOf]="videoCreationProcessList" let-i="index">
                    <div class="process-container" *ngIf="currentProcessItem === i">
                        <div class="process-header" [innerHTML]="sanHtml(item.header)"></div>
                        <div class="process-description" [innerHTML]="sanHtml(item.description)"></div>
                        <div class="process-steps">
                            <div class="step" *ngFor="let p of item.steps; index as j">
                                <div class="image">
                                    <img [src]="cdnUrl + p.image">
                                    <div class="video" *ngIf="p.video !== ''">
                                        <a (click)="openVideo(p.video)" class="video-impulse"><span class="circlephone"
                                                style="transform-origin: center;"></span><span class="circle-fill"
                                                style="transform-origin: center;"></span><span class="img-circle"
                                                style="transform-origin: center;"><span class="img-circleblock"
                                                    style="transform-origin: center;"
                                                    [style.backgroundImage]="'url('+cdnUrl+'/images/video-impulse.png'+')'"></span></span></a>
                                    </div>
                                </div>
                                <div class="description page-text" [innerHTML]="sanHtml(p.description)"></div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </section>

    <section class="section-7">
        <div class="wrapper">
            <div class="header" [innerHTML]="safeHeader5"></div>
            <div class="video-creation-tools">
                <div class="item" *ngFor="let item of videoCreationTools; index as i">
                    <span class="icon"><img [src]="cdnUrl + item.optionValue('icon')"></span>
                    <span class="name" [innerHTML]="sanHtml(item.optionValue('header7'))"></span>
                    <span class="video">
                        <a (click)="openVideo(item.optionValue('video4'))">
                            <img [src]="cdnUrl + item.optionValue('image2')">
                        </a>
                    </span>
                    <span class="link">
                        <a [routerLink]="item.optionValue('link2')">{{item.optionValue('text5')}}</a>
                    </span>
                </div>
            </div>
        </div>
    </section>

    <section class="section-8" [style.backgroundImage]="'url(' + cdnUrl + '/images/back3.png)'">
        <div class="wrapper">
            <div class="header" [innerHTML]="safeHeader6"></div>
            <div class="link">
                <a [routerLink]="page.optionValue('link3')">{{page.optionValue('text6')}}</a>
            </div>
        </div>
    </section>

</article>

<popup-modal id="popup-video" class="video">
    <a (click)="closeVideo();" class="close">&times;</a>
    <ng-template [ngIf]="popupVideo">
        <iframe [src]="popupVideo" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ng-template>
</popup-modal>

<app-footer></app-footer>