import {PageOption} from './page-option';

export class PageOptionValue {

    public id: number;
    public page_option_id: number;
    public page_id: number;
    public value: string;
    public pageOption: PageOption;

    constructor(obj) {
        this.id = obj.id;
        this.page_option_id = obj.page_option_id;
        this.page_id = obj.page_id;
        this.value = obj.value;
        this.pageOption = new PageOption(obj.pageOption);
    }
}