import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Page } from '../_models/page';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'app-tool',
  templateUrl: './tool.component.html',
  styleUrls: ['./tool.component.scss']
})
export class ToolComponent implements OnInit {

  cdnUrl = environment.cdnUrl;
  pageList: Page[] = [];
  listHeader: string;
  defaultListHeader = 'Разделы';
  content: SafeHtml;

  constructor(
    public page: Page,
    private _sanitizer: DomSanitizer,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.content = this.sanText(this.page.pageSeo.content);
    if (this.page.page_id !== null) {
      this.apiService.pageById(this.page.page_id).subscribe(p => {
        if (p.page_id !== null) {
          this.apiService.pageById(p.page_id).subscribe(top => {
            this.pageList = top.pages;
            this.listHeader = top.optionValue('header24') || this.defaultListHeader;
          });
        } else {
          this.pageList = p.pages;
          this.listHeader = p.optionValue('header24') || this.defaultListHeader;
        }
      });
    } else {
      this.pageList = this.page.pages;
      this.listHeader = this.page.optionValue('header24') || this.defaultListHeader;
    }
  }

  sanText(text: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(text);
  }

  sanHtml(text: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(text !== null ? text.replace('\n', '<br>') : '');
  }

  isActiveParentLink(url: string): boolean {
    return this.page.fullUrl.indexOf(url) > -1;
  }

}
