import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { VideoTemplate } from '../_models/video-template';
import { ApiService } from '../_services/api.service';
import { ModalService } from '../_services/modal.service';

@Component({
  selector: 'app-catalog-template-list',
  templateUrl: './catalog-template-list.component.html',
  styleUrls: ['./catalog-template-list.component.scss']
})
export class CatalogTemplateListComponent implements OnInit {

  @Input() categoryId: number;
  @Input() templateList: VideoTemplate[] = [];
  page = 1;
  cdnUrl = environment.cdnUrl;
  popupVideo: SafeResourceUrl;
  templateTotalCount: number;
  templatePageCount: number;
  templatePageSize = 10;
  moreLoading = false;

  constructor(
    private apiService: ApiService,
    private activateRoute: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private modalService: ModalService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    if (this.activateRoute.snapshot.queryParams['page'] !== undefined) {
      this.page = +this.activateRoute.snapshot.queryParams['page'];
    }
    const params: any = {
      page: this.page,
      page_size: this.templatePageSize
    };
    if (this.categoryId !== undefined) {
      params.category_id = this.categoryId;
    }
    if (this.templateList.length === 0) {
      this.activateRoute.queryParams.subscribe(par => {
        if (par['page'] !== undefined) {
          this.page = +par['page'];
        } else {
          this.page = 1;
        }
        if (!this.moreLoading) {
          this.templateList = [];
        }
        this.loadList();
      });
      this.apiService.custom('data', 'template-count', params, 'post').subscribe((count: number) => {
        this.templateTotalCount = count;
        this.templatePageCount = Math.ceil(this.templateTotalCount / this.templatePageSize);
      });
    }
  }

  loadList() {
    const params: any = {
      page: this.page,
      page_size: this.templatePageSize
    };
    if (this.categoryId !== undefined) {
      params.category_id = this.categoryId;
    }
    if (this.page !== undefined) {
      this.apiService.custom('data', 'video-template-list', params, 'post').subscribe((list: any[]) => {
        list.every((v: any) => {
          this.templateList.push(new VideoTemplate(v));
          return true;
        });
        this.moreLoading = false;
      });
    }
  }

  loadMore() {
    this.moreLoading = true;
    // const params: any = {
    //   page: this.page + 1,
    //   page_size: this.templatePageSize
    // };
    // if (this.sectionId !== undefined) {
    //   params.section_id = this.sectionId;
    // }
    // if (this.categoryId !== undefined) {
    //   params.category_id = this.categoryId;
    // }
    // this.apiService.custom('data', 'video-template-list', params, 'post').subscribe(list => {
    //   list.every(v => {
    //     this.templateList.push(new VideoTemplate(v));
    //     return true;
    //   });
    //   this.page++;
    //   this.router.navigate([], { queryParams: { page: this.page } });
    //   // this.moreLoading = false;
    // });

    this.router.navigate([], { queryParams: { page: this.page + 1 } });
  }

  createVideo(id: number) {
    /**
     * Параметры для создания видео:
     * template_id - шаблон
     */
    this.router.navigate([`/${environment.teamplateApp}/.`], { queryParams: {template_id: id }});
  }

  favoriteChange(id: number) {

  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  sanVideo(url: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url.indexOf('youtube.com') > -1 || url.indexOf('youtu.be') > -1 ? 'https://www.youtube.com/embed/' + url.replace(/.+(\?v=|youtu.be\/)/, '') + '?controls=0&autoplay=1' : this.cdnUrl + url);
  }

  openVideo(url: string) {
    this.popupVideo = this.sanVideo(url);
    this.openModal('popup-video');
  }

  closeVideo() {
    this.closeModal('popup-video');
    this.popupVideo = '';
  }

}
