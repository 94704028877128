import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/_services/api.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  currentUrl: string;
  pageList: { url: string, name: string }[];

  constructor(
    private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUrl = this.router.url;
    this.apiService.custom('data', 'breadcrumbs', { url: this.currentUrl }, 'post').subscribe((list: { url: string, name: string }[]) => {
      if (list) {
        this.pageList = [];
        list.every(v => {
          this.pageList.push(v);
          return true;
        });
      }
    });
  }

}
