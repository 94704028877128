<app-header></app-header>

<section class="section-1">
    <div class="wrapper">
        <app-breadcrumbs></app-breadcrumbs>
    </div>
</section>

<section class="section-2">
    <div class="wrapper">
        <div class="left" [ngClass]="{'wide': pageList.length === 0}">
            <h1 [innerHTML]="sanHtml(page.pageSeo.h1)" *ngIf="page.pageSeo.h1 !== ''"></h1>
            <div class="page-text" [innerHTML]="content" *ngIf="content !== undefined"></div>
        </div>
        <div class="right" *ngIf="pageList.length > 0">
            <div class="header" [innerHTML]="sanHtml(listHeader)" *ngIf="listHeader !== undefined"></div>
            <ul class="child-list">
                <li *ngFor="let item of pageList">
                    <a [routerLink]="[item.fullUrl + '.']" [routerLinkActive]="'active'" [ngClass]="{'active': isActiveParentLink(item.fullUrl)}"><span>{{item.name}}</span></a>
                    <ul *ngIf="item.pages.length > 0">
                        <li *ngFor="let sub of item.pages">
                            <a [routerLink]="[sub.fullUrl + '.']" [routerLinkActive]="'active'"><span>{{sub.name}}</span></a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</section>

<app-footer></app-footer>