import { Component, OnInit } from '@angular/core';
import { Menu } from 'src/app/_models/menu';
import { ApiService } from 'src/app/_services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-video-template-header',
  templateUrl: './video-template-header.component.html',
  styleUrls: ['./video-template-header.component.scss']
})
export class VideoTemplateHeaderComponent implements OnInit {

  cdnUrl = environment.cdnUrl;
  img1 = '/images/logob.svg';
  menu: Menu;
  langList: Menu;
  img2 = '/images/btnstngs.svg';

  constructor(
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    this.apiService.menu('template-app').subscribe(menu => {
      this.menu = new Menu(menu);
    });
    this.apiService.menu('language').subscribe((menu) => {
      this.langList = new Menu(menu);
    });
  }

  saveProject() {

  }

}
