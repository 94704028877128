import { MusicElement } from './music-element';

export class VideoProperty {
    public frame_count: number;
    public duration: number;
    public musicElement?: MusicElement;

    constructor(obj: any) {
        this.frame_count = obj.frame_count;
        this.duration = obj.duration;
        this.musicElement = obj.musicElement !== null ? new MusicElement(obj.musicElement) : null;
    }
}
