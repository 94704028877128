export class VideoCreationProcess {

    public name: string;
    public header: string;
    public description: string;
    public sort: number;
    public steps: {
        name: string,
        sort: number,
        image: string,
        description: string,
        video: string
    }[];

    constructor(obj: any) {
        this.name = obj.name;
        this.header = obj.header;
        this.description = obj.description;
        this.sort = obj.sort;
        this.steps = [];
        if (obj.steps) {
            obj.steps.every((v: {
                name: string,
                sort: number,
                image: string,
                description: string,
                video: string
            }) => {
                this.steps.push(v);
                return true;
            });
        }
    }

}
