<section class="section-1" *ngIf="videoTemplate !== undefined">
    <div class="wrapper">
        <div class="line">
            <div class="duration-info">
                Продолжительность видео {{producedDuration * 1000 | date:'mm:ss'}} / {{videoTemplate.videoProperty.duration * 1000 | date:'mm:ss'}}
            </div>
            <button class="autocreate">Создание автоматически</button>
            <button class="handcreate">Создание вручную</button>
        </div>
        <div class="work-container">
            <div class="left">
                <div class="menu-list">
                    <ul class="menu-1">
                        <li>
                            <a>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="16px"
                                        height="16px" version="1.1"
                                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                        viewBox="0 0 2252 2253" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <defs>
                                            <style type="text/css">
                                                .fil0 {
                                                    fill: #969696
                                                }
                                            </style>
                                        </defs>
                                        <g id="Слой_x0020_1">
                                            <metadata id="CorelCorpID_0Corel-Layer" />
                                            <path class="fil0"
                                                d="M1352 472c-59,-59 -155,-59 -214,0l-53 54 -748 749 0 0 -24 24c0,0 -75,76 -245,623 -1,4 -2,8 -3,12 -3,10 -6,20 -10,30 -2,9 -5,18 -8,27 -2,8 -4,16 -7,23 -5,18 -11,37 -16,55 -8,28 -24,77 -24,117l0 2c0,20 4,39 15,50 33,33 127,4 169,-8 18,-6 36,-11 54,-17 8,-2 16,-4 24,-7 9,-3 18,-5 26,-8 11,-3 21,-6 32,-10 3,0 6,-1 9,-2 521,-163 615,-239 623,-246 0,0 0,0 0,0 0,0 0,-1 0,-1l24 -24 2 2 748 -749 54 -54c59,-59 59,-155 0,-214l-428 -428zm-486 1371c-1,0 -1,1 -2,1 -1,1 -2,1 -2,1 -1,1 -1,1 -2,2 -1,0 -2,1 -2,1 -25,15 -98,53 -276,117 -20,8 -43,16 -66,24l-252 -252c8,-24 16,-47 23,-68 64,-178 103,-251 118,-276 0,0 0,-1 1,-1 0,-1 1,-2 1,-3 0,-1 1,-1 1,-1 1,-1 1,-2 2,-3l18 -18 457 457 -19 19zm1341 -1371l-427 -428c-59,-59 -155,-59 -214,0l-107 107c-59,59 -59,155 0,214l427 428c60,59 155,59 214,0l107 -107c59,-59 59,-155 0,-214z" />
                                        </g>
                                    </svg>
                                </span>
                                <span class="name">Изменить шаблон</span>
                            </a>
                        </li>
                        <li>
                            <a>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="19px"
                                        height="19px" version="1.1"
                                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                        viewBox="0 0 3151 3151" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <defs>
                                            <style type="text/css">
                                                .str0 {
                                                    stroke: #969696;
                                                    stroke-width: 146.763
                                                }

                                                .fil2 {
                                                    fill: none
                                                }

                                                .fil1 {
                                                    fill: #969696;
                                                    fill-rule: nonzero
                                                }
                                            </style>
                                        </defs>
                                        <g id="Слой_x0020_1">
                                            <metadata id="CorelCorpID_0Corel-Layer" />
                                            <g id="_1135445920">
                                                <circle class="fil2 str0" cx="1576" cy="1576" r="1502" />
                                                <polygon class="fil1"
                                                    points="1109,2370 1109,781 1369,781 1369,2148 2042,2148 2042,2370 " />
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <span class="name">Добавить логотип</span>
                            </a>
                        </li>
                        <li>
                            <a>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="18px"
                                        height="15px" version="1.1"
                                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                        viewBox="0 0 2857 2285" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <defs>
                                            <style type="text/css">
                                                .fil3 {
                                                    fill: #969696
                                                }
                                            </style>
                                        </defs>
                                        <g id="Слой_x0020_1">
                                            <metadata id="CorelCorpID_0Corel-Layer" />
                                            <path class="fil3"
                                                d="M1428 857c-236,0 -428,192 -428,428 0,237 192,429 428,429 237,0 429,-192 429,-429 0,-236 -192,-428 -429,-428zm1143 -428l-343 0c-47,0 -98,-37 -113,-82l-88 -266c-15,-44 -66,-81 -113,-81l-971 0c-48,0 -98,37 -113,81l-89 266c-15,45 -65,82 -112,82l-343 0c-157,0 -286,128 -286,285l0 1286c0,157 129,285 286,285l2285 0c157,0 286,-128 286,-285l0 -1286c0,-157 -129,-285 -286,-285zm-1143 1571c-394,0 -714,-320 -714,-715 0,-394 320,-714 714,-714 395,0 714,320 714,714 0,395 -319,715 -714,715zm1043 -1086c-55,0 -100,-45 -100,-100 0,-55 45,-100 100,-100 55,0 100,45 100,100 0,55 -45,100 -100,100z" />
                                        </g>
                                    </svg>
                                </span>
                                <span class="name">Добавить фото и видео</span>
                            </a>
                        </li>
                        <li>
                            <a>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="17px"
                                        height="17px" version="1.1"
                                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                        viewBox="0 0 2543 2439" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <defs>
                                            <style type="text/css">
                                                .fil4 {
                                                    fill: #969696
                                                }
                                            </style>
                                        </defs>
                                        <g id="Слой_x0020_1">
                                            <metadata id="CorelCorpID_0Corel-Layer" />
                                            <g id="_1135467392">
                                                <path class="fil4"
                                                    d="M2097 0l-1651 0c-246,0 -446,200 -446,446l0 1018c0,246 200,446 446,446l1051 0 504 503c17,17 40,26 63,26 11,0 23,-2 34,-6 33,-14 55,-47 55,-83l0 -444c219,-27 390,-215 390,-442l0 -1018c0,-246 -200,-446 -446,-446zm267 1464c0,148 -120,268 -267,268l-33 0c-49,0 -89,40 -89,89l0 314 -378 -377c-16,-17 -39,-26 -63,-26l-1088 0c-148,0 -268,-120 -268,-268l0 -1018c0,-148 120,-268 268,-268l1651 0c147,0 267,120 267,268l0 1018z" />
                                                <path class="fil4"
                                                    d="M1927 631l-1312 0c-49,0 -89,40 -89,89 0,50 40,90 89,90l1312 0c50,0 90,-40 90,-90 0,-49 -40,-89 -90,-89z" />
                                                <path class="fil4"
                                                    d="M1927 1130l-1312 0c-49,0 -89,40 -89,90 0,49 40,89 89,89l1312 0c50,0 90,-40 90,-89 0,-50 -40,-90 -90,-90z" />
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <span class="name">Добавить текст</span>
                            </a>
                        </li>
                        <li>
                            <a>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="16px"
                                        height="18px" version="1.1"
                                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                        viewBox="0 0 2169 2411" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <defs>
                                            <style type="text/css">
                                                .fil5 {
                                                    fill: #969696
                                                }
                                            </style>
                                        </defs>
                                        <g id="Слой_x0020_1">
                                            <metadata id="CorelCorpID_0Corel-Layer" />
                                            <path class="fil5"
                                                d="M665 372l0 1406c-70,-51 -160,-82 -258,-82 -224,0 -407,161 -407,358 0,197 183,357 407,357 224,0 406,-160 406,-357l0 -1221 1208 -299 0 909c-70,-51 -161,-82 -259,-82 -224,0 -406,160 -406,357 0,197 182,358 406,358 224,0 407,-161 407,-358l0 -1718 -1504 372z" />
                                        </g>
                                    </svg>
                                </span>
                                <span class="name">Выбрать музыку</span>
                            </a>
                        </li>
                    </ul>
                    <ul class="menu-2" *ngIf="selectedItem">
                        <li>
                            <a>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="12px"
                                        height="10px" version="1.1"
                                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                        viewBox="0 0 1221 992" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <defs>
                                            <style type="text/css">
                                                .fil6 {
                                                    fill: #969696
                                                }
                                            </style>
                                        </defs>
                                        <g id="Слой_x0020_1">
                                            <metadata id="CorelCorpID_0Corel-Layer" />
                                            <path class="fil6"
                                                d="M0 0l0 992 1221 0 0 -992 -1221 0zm1145 916l-1069 0 0 -840 1069 0 0 840zm-305 -649c0,63 51,115 114,115 63,0 115,-52 115,-115 0,-63 -52,-114 -115,-114 -63,0 -114,51 -114,114zm229 573l-916 0 229 -611 305 382 153 -115 229 344z" />
                                        </g>
                                    </svg>
                                </span>
                                <span class="name">Фоторедактор</span>
                            </a>
                        </li>
                        <li>
                            <a>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="11px"
                                        height="11px" version="1.1"
                                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                        viewBox="0 0 1010 1015" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <defs>
                                            <style type="text/css">
                                                .fil7 {
                                                    fill: #969696
                                                }
                                            </style>
                                        </defs>
                                        <g id="Слой_x0020_1">
                                            <metadata id="CorelCorpID_0Corel-Layer" />
                                            <path class="fil7"
                                                d="M935 589c28,-3 54,-15 74,-34l0 350c0,61 -50,110 -110,110l-789 0c-61,0 -110,-49 -110,-110l0 -787c0,-61 49,-110 110,-110l352 0c-19,19 -32,45 -34,73l-318 0c-20,0 -36,16 -36,37l0 787c0,20 16,37 36,37l789 0c20,0 36,-17 36,-37l0 -316zm20 -589l-374 0c-30,0 -55,25 -55,55 0,31 25,55 55,55l238 0c-2,2 -5,4 -7,6l-389 389 0 -241c0,-30 -25,-55 -55,-55 -16,0 -29,6 -39,16 -10,10 -16,24 -16,39l0 374c0,31 24,55 55,55l373 0c31,0 56,-24 56,-55 0,-30 -25,-55 -56,-55l-237 0c2,-2 4,-3 7,-5l389 -390 0 241c0,30 25,55 55,55 15,0 29,-6 39,-16 10,-10 16,-24 16,-39l0 -374c0,-30 -24,-55 -55,-55z" />
                                        </g>
                                    </svg>
                                </span>
                                <span class="name">Выбрать размер фото</span>
                            </a>
                        </li>
                        <li>
                            <a>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="11px"
                                        height="11px" version="1.1"
                                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                        viewBox="0 0 1017 1017" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <defs>
                                            <style type="text/css">
                                                .fil8 {
                                                    fill: #969696
                                                }
                                            </style>
                                        </defs>
                                        <g id="Слой_x0020_1">
                                            <metadata id="CorelCorpID_0Corel-Layer" />
                                            <path class="fil8"
                                                d="M990 0l-963 0c-15,0 -27,12 -27,27l0 963c0,15 12,27 27,27l963 0c15,0 27,-12 27,-27l0 -963c0,-15 -12,-27 -27,-27zm-306 230l75 -155c3,-5 8,-9 14,-9l126 0c5,0 10,3 13,8 3,4 3,10 1,15l-75 154c-3,6 -9,9 -15,9l-125 0c-5,0 -10,-2 -13,-7 -3,-5 -4,-11 -1,-15zm-290 0l75 -155c3,-5 9,-9 15,-9l125 0c5,0 10,3 13,8 3,4 4,10 1,15l-75 154c-3,6 -8,9 -14,9l-126 0c-5,0 -10,-2 -13,-7 -3,-5 -3,-11 -1,-15zm-290 0l76 -155c2,-5 8,-9 14,-9l125 0c5,0 11,3 13,8 3,4 4,10 1,15l-75 154c-3,6 -8,9 -14,9l-126 0c-5,0 -10,-2 -13,-7 -3,-5 -3,-11 -1,-15zm830 689c0,9 -7,16 -16,16l-819 0c-9,0 -16,-7 -16,-16l0 -584c0,-9 7,-16 16,-16l819 0c9,0 16,7 16,16l0 584z" />
                                        </g>
                                    </svg>
                                </span>
                                <span class="name">Видеоредактор</span>
                            </a>
                        </li>
                        <li>
                            <a>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="11px"
                                        height="9px" version="1.1"
                                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                        viewBox="0 0 1017 850" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <defs>
                                            <style type="text/css">
                                                .fil9 {
                                                    fill: #969696
                                                }
                                            </style>
                                        </defs>
                                        <g id="Слой_x0020_1">
                                            <metadata id="CorelCorpID_0Corel-Layer" />
                                            <path class="fil9"
                                                d="M965 0l-913 0c-29,0 -52,23 -52,52l0 746c0,29 23,52 52,52l913 0c29,0 52,-23 52,-52l0 -746c0,-29 -23,-52 -52,-52zm-519 62l125 0 0 97 -125 0 0 -97zm-148 727l-125 0 0 -97 125 0 0 97zm0 -630l-125 0 0 -97 125 0 0 97zm273 630l-125 0 0 -97 125 0 0 97zm46 -352l-145 107c-3,2 -6,3 -9,3 -2,0 -4,-1 -6,-2 -5,-2 -8,-7 -8,-13l0 -214c0,-5 3,-10 8,-13 5,-2 10,-2 15,1l145 107c4,3 6,7 6,12 0,5 -2,9 -6,12zm227 352l-124 0 0 -97 124 0 0 97zm0 -630l-124 0 0 -97 124 0 0 97z" />
                                        </g>
                                    </svg>
                                </span>
                                <span class="name">Выбрать отрезок видео</span>
                            </a>
                        </li>
                        <li>
                            <a>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="7px"
                                        height="7px" version="1.1"
                                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                        viewBox="0 0 446 446" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <defs>
                                            <style type="text/css">
                                                .fil10 {
                                                    fill: #969696
                                                }
                                            </style>
                                        </defs>
                                        <g id="Слой_x0020_1">
                                            <metadata id="CorelCorpID_0Corel-Layer" />
                                            <path class="fil10"
                                                d="M435 0l-424 0c-6,0 -11,5 -11,11l0 180c0,7 7,7 7,7l232 0c5,0 9,6 9,10l0 230c0,0 0,8 7,8l180 0c6,0 11,-5 11,-11l0 -424c0,-6 -5,-11 -11,-11z" />
                                        </g>
                                    </svg>
                                </span>
                                <span class="name">Дублировать</span>
                            </a>
                        </li>
                        <li>
                            <a>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="11px"
                                        height="11px" version="1.1"
                                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                        viewBox="0 0 1014 1014" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <defs>
                                            <style type="text/css">
                                                .fil11 {
                                                    fill: #969696
                                                }
                                            </style>
                                        </defs>
                                        <g id="Слой_x0020_1">
                                            <metadata id="CorelCorpID_0Corel-Layer" />
                                            <path class="fil11"
                                                d="M935 459l-190 191c-105,105 -276,105 -381,0 -16,-17 -29,-35 -41,-54l89 -89c4,-4 9,-7 14,-9 6,20 17,40 33,57 53,52 138,52 191,0l190 -191c53,-52 53,-138 0,-190 -52,-52 -138,-52 -190,0l-68 68c-55,-22 -114,-27 -171,-19l144 -144c105,-105 275,-105 380,0 106,105 106,275 0,380zm-503 313l-68 68c-52,53 -138,53 -190,0 -52,-52 -52,-138 0,-190l190 -191c53,-52 138,-52 191,0 16,17 27,37 33,58 5,-3 10,-6 14,-10l89 -88c-12,-20 -25,-38 -41,-55 -105,-105 -276,-105 -381,0l-190 191c-105,105 -105,275 0,380 105,106 275,106 380,0l145 -144c-58,9 -117,3 -172,-19z" />
                                        </g>
                                    </svg>
                                </span>
                                <span class="name">Просмотр отрезка</span>
                            </a>
                        </li>
                        <li>
                            <a>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="7px"
                                        height="8px" version="1.1"
                                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                        viewBox="0 0 427 507" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <defs>
                                            <style type="text/css">
                                                .fil12 {
                                                    fill: #969696
                                                }
                                            </style>
                                        </defs>
                                        <g id="Слой_x0020_1">
                                            <metadata id="CorelCorpID_0Corel-Layer" />
                                            <path class="fil12"
                                                d="M23 475c0,18 15,32 33,32l315 0c18,0 33,-14 34,-32l22 -475 -427 0 23 475zm262 -357c0,-8 6,-14 14,-14l22 0c7,0 13,6 13,14l0 271c0,8 -6,14 -13,14l-22 0c-8,0 -14,-6 -14,-14l0 -271zm-96 0c0,-8 6,-14 14,-14l22 0c7,0 13,6 13,14l0 271c0,8 -6,14 -13,14l-22 0c-8,0 -14,-6 -14,-14l0 -271zm-96 0c0,-8 6,-14 14,-14l22 0c7,0 14,6 14,14l0 271c0,8 -7,14 -14,14l-22 0c-8,0 -14,-6 -14,-14l0 -271z" />
                                        </g>
                                    </svg>
                                </span>
                                <span class="name">Удалить</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="buttons">
                    <button class="preview-video">Посмотреть видео</button>
                    <button class="save-video">Сохранить видео</button>
                </div>
            </div>
            <div class="right">
                <div class="metabox">
                    <div class="musicbox">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="19px" height="21px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                viewBox="0 0 4912 5461" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <defs>
                                    <style type="text/css">
                                        .fil13 {
                                            fill: #C5C6C6
                                        }
                                    </style>
                                </defs>
                                <g id="Слой_x0020_1">
                                    <metadata id="CorelCorpID_0Corel-Layer" />
                                    <path class="fil13"
                                        d="M1507 842l0 3186c-159,-116 -364,-186 -586,-186 -508,0 -921,363 -921,809 0,447 413,810 921,810 508,0 921,-363 921,-810l0 -2765 2735 -677 0 2059c-159,-116 -364,-185 -586,-185 -508,0 -921,363 -921,809 0,446 413,809 921,809 508,0 921,-363 921,-809l0 -3892 -3405 842z" />
                                </g>
                            </svg>
                        </div>
                        <div class="desc">
                            <div class="change">
                                <a (click)="sendWorkStatus('M')" class="">Изменить музыку</a>
                            </div>
                            <div class="name" *ngIf="videoTemplate.videoProperty.musicElement !== null">
                                {{videoTemplate.videoProperty.musicElement.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>