export class VideoSample {
    public id: number;
    public video_template_id: number;
    public name: string;
    public cover: string;
    public video: string;
    public top: boolean;

    constructor(obj: any) {
        this.id = obj.id;
        this.video_template_id = obj.video_template_id;
        this.name = obj.name;
        this.cover = obj.cover;
        this.video = obj.video;
        this.top = obj.top === 1;
    }
}
