import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from 'src/app/_models/page';

@Component({
  selector: 'app-catalog-category-element',
  templateUrl: './catalog-category-element.component.html',
  styleUrls: ['./catalog-category-element.component.scss']
})
export class CatalogCategoryElementComponent implements OnInit {

  @Input() item: Page;
  @Input() categoryId: number;
  isActive = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isActive = this.router.url.indexOf(this.item.fullUrl) > -1;
  }

}
