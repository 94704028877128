import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../_services/api.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private api: ApiService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        // const token = localStorage.getItem('token');
        // if (token) {
        //     request = request.clone({
        //         setHeaders: {
        //             Authorization: `Bearer ${token.toString()}`
        //         }
        //     });
        // } else {
        //     /**
        //      * @todo Авторизация в качестве гостя во всплывающем окне
        //      */
        // }

        return next.handle(request);
    }
}