<app-header></app-header>

<section class="section-1" [style.backgroundImage]="back1 !== undefined && back1 !== '' ? 'url(' + cdnUrl + back1 + ')' : 'none'">
    <div class="wrapper">
        <div class="header" [innerHTML]="header1"></div>
        <div class="subheader" [innerHTML]="header2"></div>
        <div class="steps">
            <div class="step step-1">
                <div class="desc">
                    <div class="arrow"><img [src]="cdnUrl + '/images/ar2.png'"></div>
                    <div class="num"><span>1</span></div>
                    <div class="text" [innerHTML]="text1"></div>
                </div>
                <div class="image">
                    <img [src]="cdnUrl + '/images/img1.png'" alt="">
                </div>
            </div>
            <div class="step step-2">
                <div class="desc">
                    <div class="arrow"><img [src]="cdnUrl + '/images/ar2.png'"></div>
                    <div class="num"><span>2</span></div>
                    <div class="text" [innerHTML]="text2"></div>
                </div>
                <div class="image">
                    <img [src]="cdnUrl + '/images/img1.png'" alt="">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-2">
    <div class="wrapper">
        <div class="header" [innerHTML]="header3"></div>
        <div class="text page-text" [innerHTML]="text3"></div>
    </div>
</section>

<ng-template [ngIf]="categoryId !== undefined">
    <app-catalog-container [header]="header4" [categoryId]="categoryId"></app-catalog-container>
</ng-template>

<section class="section-content">
    <div class="wrapper page-text">
        <h1 [innerHTML]="sanHtml(page.pageSeo.h1)"></h1>
        <div class="" [innerHTML]="sanText(page.pageSeo.content)"></div>
    </div>
</section>

<app-footer></app-footer>