import { Component, Input, OnInit } from '@angular/core';
import { Page } from 'src/app/_models/page';
import { ApiService } from 'src/app/_services/api.service';

@Component({
  selector: 'app-article-section-list',
  templateUrl: './article-section-list.component.html',
  styleUrls: ['./article-section-list.component.scss']
})
export class ArticleSectionListComponent implements OnInit {

  @Input() currentArticleSectionId: number;
  articleSections: Page[];
  pageTypeId = 8;

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.apiService.pages(this.pageTypeId).subscribe(list => {
      if (list) {
        this.articleSections = [];
        list.every(v => {
          this.articleSections.push(new Page(v));
          return true;
        });
      }
    });
  }

}
