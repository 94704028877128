<li *ngIf="item !== undefined">
    <!-- /* && catalogCategoryListCount[item.id] > 0 */ -->
    <a [routerLink]="item.fullUrl + '.'" routerLinkActive="active" [ngClass]="{ 'active': isActive, 'has-child': item.pages.length > 0 }">
        <span class="name">{{item.name}}</span>
    </a>
    <ul *ngIf="item.pages.length > 0 && isActive">
        <ng-template ngFor let-child [ngForOf]="item.pages">
            <app-catalog-category-element [item]="child" [categoryId]="categoryId"></app-catalog-category-element>
        </ng-template>
    </ul>
</li>