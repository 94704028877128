import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Page } from '../_models/page';
import { SiteOption } from '../_models/site-option';
import { Menu } from '../_models/menu';
import { VideoTemplate } from '../_models/video-template';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private apiUrl: string;
    private lang: string;

    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl;
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    custom(controller: string, action: string, params: any, method: string = 'get'): any {
        let url = this.apiUrl + controller + '/' + action;
        if (method === 'get') {
            if (params.keys().length > 0) {
                url += '?';
                for (const key in params) {
                    if (params[key] !== undefined) {
                        url += key + '=' + params[key] + '&';
                    }
                }
            }
            return this.http.get(url).pipe();
        } else if (method === 'post') {
            return this.http.post(url, params).pipe();
        }
    }

    pages(filterPageType: number = null): Observable<Page[]> {
        const url = this.apiUrl + 'page' + (filterPageType ? '?filter[page_type_id]=' + filterPageType + '&sort=sort' : '?sort=sort') + '&filter[status]=1';
        return this.http
            .get<Page[]>(url)
            .pipe();
    }

    pageByUrl(url: string): Observable<Page> {
        return this.custom('data', 'page-by-url', { url }, 'post')
            .pipe();
    }

    pageById(id: number): Observable<Page> {
        const url = this.apiUrl + 'page/' + id;
        return this.http
            .get<Page>(url)
            .pipe();
    }

    siteOption(id: string): Observable<SiteOption> {
        const url = this.apiUrl + 'site-option/' + id;
        return this.http.get<SiteOption>(url).pipe();
    }

    menu(position: string): Observable<Menu> {
        const url = this.apiUrl + 'menu/' + position;
        return this.http.get<Menu>(url).pipe();
    }

    getVideoTemplate(id: number): Observable<VideoTemplate> {
        return this.http.get<VideoTemplate>(environment.apiUrl + 'video-template/' + id).pipe();
    }
}
