import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PageComponent } from './page/page.component';
import { HeaderComponent } from './header/header.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { ApiService } from './_services/api.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { PageTextComponent } from './page-text/page-text.component';
import { ModalComponent } from './_directives/modal/modal.component';
import { FooterComponent } from './footer/footer.component';
import { CatalogComponent } from './catalog/catalog.component';
import { CatalogSectionListComponent } from './catalog-section-list/catalog-section-list.component';
import { CatalogCategoryListComponent } from './catalog-category-list/catalog-category-list.component';
import { CatalogTemplateListComponent } from './catalog-template-list/catalog-template-list.component';
import { CatalogContainerComponent } from './catalog-container/catalog-container.component';
import { CatalogCategoryElementComponent } from './_view/catalog-category-element/catalog-category-element.component';
import { Location, registerLocaleData } from '@angular/common';
import localRu from '@angular/common/locales/ru';
import { BlogComponent } from './blog/blog.component';
import { ArticleComponent } from './article/article.component';
import { BreadcrumbsComponent } from './_view/breadcrumbs/breadcrumbs.component';
import { ArticleSectionListComponent } from './_view/article-section-list/article-section-list.component';
import { StructuralComponent } from './structural/structural.component';
import { ToolComponent } from './tool/tool.component';
import { CreateFromTemplateComponent } from './create-from-template/create-from-template.component';
import { VideoTemplateHeaderComponent } from './_view/video-template-header/video-template-header.component';
import { VideoTemplateMusicComponent } from './_view/video-template-music/video-template-music.component';
import { VideoTemplateWorkplaceComponent } from './_view/video-template-workplace/video-template-workplace.component';
import { VideoTemplateCandidateTrackComponent } from './_view/video-template-candidate-track/video-template-candidate-track.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        PageComponent,
        HeaderComponent,
        NotFoundComponent,
        PageTextComponent,
        ModalComponent,
        FooterComponent,
        CatalogComponent,
        CatalogSectionListComponent,
        CatalogCategoryListComponent,
        CatalogTemplateListComponent,
        CatalogContainerComponent,
        CatalogCategoryElementComponent,
        BlogComponent,
        ArticleComponent,
        BreadcrumbsComponent,
        ArticleSectionListComponent,
        StructuralComponent,
        ToolComponent,
        CreateFromTemplateComponent,
        VideoTemplateHeaderComponent,
        VideoTemplateMusicComponent,
        VideoTemplateWorkplaceComponent,
        VideoTemplateCandidateTrackComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        HttpClientModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        ApiService
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        HomeComponent,
        NotFoundComponent,
        PageTextComponent
    ]
})
export class AppModule { }

registerLocaleData(localRu);

const __stripTrailingSlash = (Location as any).stripTrailingSlash;
Location.stripTrailingSlash = (url) => {
    const parts = url.split('?');
    if (parts[0].endsWith('/')) {
        url = url;
    }
    else {
        url = parts.join('/?');
    }
    const queryString$ = url.match(/([^?]*)?(.*)/);
    if (queryString$[2].length > 0) {
        return /[^\/]\/$/.test(queryString$[1]) ? queryString$[1] + '.' + queryString$[2] : __stripTrailingSlash(url);
    }
    return /[^\/]\/$/.test(url) ? url + '.' : __stripTrailingSlash(url);
};
