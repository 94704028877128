<footer>
    <div class="wrapper">
        <div class="menu" *ngIf="menu !== undefined">
            <ul>
                <ng-template ngFor let-item [ngForOf]="menu.menuContents">
                    <li *ngIf="item.menu_content_id===null">
                        <a *ngIf="item.url" [routerLink]="item.url" class="item" routerLinkActive="active">
                            <span class="name">{{ item.name }}</span>
                        </a>
                        <span *ngIf="!item.url" class="item">
                            <span class="name">{{ item.name }}</span>
                        </span>
                        <ul *ngIf="item.menuContents.length > 0">
                            <li *ngFor="let sub of item.menuContents">
                                <a *ngIf="sub.url" [routerLink]="sub.url + '.'" class="item" routerLinkActive="active">
                                    <span *ngIf="sub.image" class="image"><img [src]="cdnUrl + sub.image"></span>
                                    <span class="name">{{ sub.name }}</span>
                                </a>
                                <span *ngIf="!sub.url" class="item">
                                    <span *ngIf="sub.image" class="image"><img [src]="cdnUrl + sub.image"></span>
                                <span class="name">{{ sub.name }}</span>
                                </span>
                            </li>
                        </ul>
                    </li>
                </ng-template>
            </ul>
        </div>
        <div class="language" *ngIf="langList !== undefined">
            <ul>
                <ng-template ngFor let-item let-i="index" [ngForOf]="langList.menuContents">
                    <li>
                        <a *ngIf="i > 0" [routerLink]="item.url">
                            <img [src]="cdnUrl+item.image"><span>{{ item.name }}</span>
                        </a>
                        <span *ngIf="i === 0">{{ item.name }}</span>
                    </li>
                </ng-template>
            </ul>
        </div>
        <div class="socials" *ngIf="socials !== undefined">
            <ul>
                <li *ngFor="let item of socials.menuContents">
                    <a href="{{item.url}}"><img [src]="cdnUrl + item.image" alt=""></a>
                </li>
            </ul>
        </div>
        <div class="copyright" *ngIf="copyright !== undefined" [innerHTML]="copyright"></div>
    </div>
</footer>