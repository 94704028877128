import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Menu } from '../_models/menu';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  menu: Menu;
  cdnUrl = environment.cdnUrl;
  langList: Menu;
  socials: Menu;
  copyright: SafeHtml;

  constructor(
    private apiService: ApiService,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.apiService.menu('footer')
      .subscribe((menu) => {
        this.menu = new Menu(menu);
      });

    this.apiService.menu('language')
      .subscribe((menu) => {
        this.langList = new Menu(menu);
      });

    this.apiService.menu('socials')
      .subscribe((menu) => {
        this.socials = new Menu(menu);
      });

    this.apiService.siteOption('copyright').subscribe(r => {
      this.copyright = this._sanitizer.bypassSecurityTrustHtml(r.siteOptionValue.value.replace('\n', '<br>'));
    });
  }

}
