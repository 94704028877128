<section>
    <div class="wrapper">
        <div class="header" [innerHTML]="header"></div>
        <div class="catalog">
            <div class="left">
                <app-catalog-section-list [categoryId]="categoryId"></app-catalog-section-list>
                <app-catalog-category-list [categoryId]="categoryId"></app-catalog-category-list>
            </div>
            <div class="right">
                <app-catalog-template-list [categoryId]="categoryId"></app-catalog-template-list>
            </div>
        </div>
    </div>
</section>