import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MusicElement } from 'src/app/_models/music-element';
import { VideoTemplate } from 'src/app/_models/video-template';

@Component({
  selector: 'app-video-template-music',
  templateUrl: './video-template-music.component.html',
  styleUrls: ['./video-template-music.component.scss']
})
export class VideoTemplateMusicComponent implements OnInit {

  @Input() videoTemplate: VideoTemplate;
  @Input() workStatus: string;
  @Output() workStatusChange = new EventEmitter<string>();

  loading = false;
  loaded = 0;
  loadedPc = 0;
  browserShow = false;
  browserMode = 'C';
  candidateTrack: MusicElement = new MusicElement({
    id: 1, musician: 'ededed', cover: '',
    name: 'string',
    src: '/templates/4/music/audio.wav',
    duration: 78
  });

  constructor() { }

  ngOnInit(): void {
  }

  sendWorkStatus(m: string) {
    this.workStatus = m;
    this.workStatusChange.emit(this.workStatus);
  }

  fromCollections() {
    this.browserMode = 'C';
    this.browserShow = true;
  }

  fromFilesystem() { }

  fromVk() { }

  fromOk() { }

  fromFb() { }

  countTrackLength(): number {
    return 0;
  }

  countTrackLengthPercent(): number {
    return Math.round(this.loaded * 100 / this.videoTemplate.videoProperty.duration);
  }

  repeatTrack() {

  }

}
