import {Component, OnInit, Input, ElementRef, OnDestroy, Inject} from '@angular/core';
import {ModalService} from '../../_services/modal.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'popup-modal',
    template: `<div class="popup-modal">
            <div class="popup-modal-body">
                <ng-content></ng-content>
            </div>
        </div>
        <div class="popup-modal-background"></div>`,
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {

    @Input() id: string;
    private element: any;

    constructor(
        private modalService: ModalService, 
        private el: ElementRef,
        @Inject(DOCUMENT) private document: Document) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        const modal = this;

        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        this.document.body.appendChild(this.element);

        // close modal on background click
        // this.element.addEventListener('click', (e: any) => {
        //         if (e.target.className === 'popup-modal') {
        //             modal.close();
        //         }
        //     });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when directive is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(): void {
        this.element.style.display = 'block';
        this.document.body.classList.add('popup-modal-open');
    }

    // close modal
    close(): void {
        this.element.style.display = 'none';
        this.document.body.classList.remove('popup-modal-open');
    }

}
