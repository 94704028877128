export class PageSeo {

    id: number;
    url: string;
    h1: string;
    title: string;
    keywords: string;
    description: string;
    content: string;
    noindex: number;

    constructor(obj: any) {
        this.id = obj.id;
        this.url = obj.url;
        this.h1 = obj.h1;
        this.title = obj.title;
        this.keywords = obj.keywords;
        this.description = obj.description;
        this.content = obj.content;
        this.noindex = obj.noindex;
    }
}
