import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VideoCreationProcess } from '../_models/video-creation-process';

@Injectable({
  providedIn: 'root'
})
export class VideoCreationProcessService {

  constructor(private http: HttpClient) { }

  list(): Observable<VideoCreationProcess[]> {
    return this.http.get<VideoCreationProcess[]>(environment.apiUrl + 'data/video-creation-process-list').pipe();
  }
}
