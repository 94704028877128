import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-catalog-container',
  templateUrl: './catalog-container.component.html',
  styleUrls: ['./catalog-container.component.scss']
})
export class CatalogContainerComponent implements OnInit {

  @Input() header: SafeHtml;
  @Input() categoryId: number;

  constructor() {
  }

  ngOnInit(): void {
  }

}
