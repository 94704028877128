<app-header></app-header>

<section class="section-1" [style.backgroundImage]="'url(' + cdnUrl + back1 + ')'">
    <div class="wrapper">
        <div class="header" [innerHTML]="header1"></div>
        <div class="subheader" [innerHTML]="header2"></div>
        <div class="steps">
            <div class="step step-1">
                <div class="desc">
                    <div class="arrow"><img [src]="cdnUrl + '/images/ar2.png'"></div>
                    <div class="num"><span>1</span></div>
                    <div class="text" [innerHTML]="text1"></div>
                </div>
                <div class="image">
                    <img [src]="cdnUrl + '/images/img1.png'" alt="">
                </div>
            </div>
            <div class="step step-2">
                <div class="desc">
                    <div class="arrow"><img [src]="cdnUrl + '/images/ar2.png'"></div>
                    <div class="num"><span>2</span></div>
                    <div class="text" [innerHTML]="text2"></div>
                </div>
                <div class="image">
                    <img [src]="cdnUrl + '/images/img1.png'" alt="">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-2">
    <div class="wrapper">
        <app-breadcrumbs></app-breadcrumbs>
    </div>
</section>

<section class="section-3" *ngIf="page.optionValue('text22') !== '' && page.optionValue('text22') !== null">
    <div class="wrapper page-text" [innerHTML]="text3"></div>
</section>

<section class="section-4" *ngIf="templateList.length > 0">
    <div class="wrapper">
        <div class="header" *ngIf="header3 !== undefined" [innerHTML]="header3"></div>
        <app-catalog-template-list [templateList]="templateList"></app-catalog-template-list>
        <div class="showall">
            <a [routerLink]="['/catalog/.']">Посмотреть все</a>
        </div>
    </div>
</section>

<section class="section-5" *ngIf="page.optionValue('text23') !== '' && page.optionValue('text23') !== null">
    <div class="wrapper page-text" [innerHTML]="text4"></div>
</section>

<app-footer></app-footer>