import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Page } from '../_models/page';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {

  cdnUrl = environment.cdnUrl;
  header1: SafeHtml;
  header2: SafeHtml;
  text1: SafeHtml;
  text2: SafeHtml;
  header3: SafeHtml;
  text3: SafeHtml;
  header4: SafeHtml;
  categoryId: number;
  back1: string;

  constructor(
    public page: Page,
    private _sanitizer: DomSanitizer
  ) {
    if (this.page.page_type_id === 6) {
      this.categoryId = this.page.id;
    } else {
      this.categoryId = 0;
    }
  }

  ngOnInit(): void {
    this.header1 = this.sanHtml(this.page.optionValue('header9') || this.page.optionValue('header12') || this.page.optionValue('header15'));
    this.header2 = this.sanHtml(this.page.optionValue('text7') || this.page.optionValue('text11') || this.page.optionValue('text15'));
    this.header3 = this.sanHtml(this.page.optionValue('header10') || this.page.optionValue('header13') || this.page.optionValue('header16'));
    this.header4 = this.sanHtml(this.page.optionValue('header11') || this.page.optionValue('header14') || this.page.optionValue('header17'));
    this.text1 = this.sanHtml(this.page.optionValue('text8') || this.page.optionValue('text12') || this.page.optionValue('text16'));
    this.text2 = this.sanHtml(this.page.optionValue('text9') || this.page.optionValue('text13') || this.page.optionValue('text17'));
    this.text3 = this.sanHtml(this.page.optionValue('text10') || this.page.optionValue('text14') || this.page.optionValue('text18'));
    this.back1 = this.page.optionValue('image4') || this.page.optionValue('image5') || '';
  }

  sanHtml(text: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(text !== null ? text.replace('\n', '<br>') : '');
  }

  sanText(text: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(text);
  }

}
