import { PageType } from './page-type';
import { Template } from './template';
import { PageOptionValue } from './page-option-value';
import { PageOption } from './page-option';
import { PageSeo } from './page-seo';
import { Injectable } from '@angular/core';

@Injectable()
export class Page {

    public id: number;
    public name?: string;
    public sort?: number;
    public page_type_id?: number;
    public page_id?: number;
    public template_id?: number;
    public status?: number;
    public created_at?: number;
    public updated_at?: number;
    public page?: Page;
    public pages?: Page[];
    public pageType?: PageType;
    public template?: Template;
    public pageOptionValues?: PageOptionValue[];
    public pageOptions?: PageOption[];
    public pageSeo?: PageSeo;
    public fullUrl?: string;
    public path?: number[];
    public depth?: number;
    public plainExpand?: number[];

    constructor(page: any) {
        this.id = page.id;
        this.name = page.name;
        this.sort = page.sort;
        this.page_type_id = page.page_type_id;
        this.page_id = page.page_id;
        this.template_id = page.template_id;
        this.status = page.status;
        this.created_at = page.created_at;
        this.updated_at = page.updated_at;
        this.page = page.page;
        this.pages = [];
        if (page.pages) {
            page.pages.every(v => {
                this.pages.push(new Page(v));
                return true;
            });
        }
        this.pageType = page.pageType;
        this.template = page.template;
        if (page.pageOptionValues) {
            this.pageOptionValues = [];
            page.pageOptionValues.every(v => {
                this.pageOptionValues.push(new PageOptionValue(v));
                return true;
            });
        }
        if (page.pageOptions) {
            this.pageOptions = [];
            page.pageOptions.every(v => {
                this.pageOptions.push(new PageOption(v));
                return true;
            });
        }
        this.pageSeo = page.pageSeo !== null ? new PageSeo(page.pageSeo) : null;
        this.fullUrl = page.fullUrl;
        this.path = page.path;
        this.depth = page.depth;
        this.plainExpand = page.plainExpand;
    }

    public optionValue(code: string): string {
        for (const item of this.pageOptionValues) {
            if (item.pageOption.code === code) {
                return item.value;
            }
        }
        return null;
    }

}
