<header>
    <section class="wrapper">
        <div class="logo" *ngIf="logo !== undefined">
            <img [src]="cdnUrl + logo">
            <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="24.9993mm" height="3.4002mm" version="1.0" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                viewBox="0 0 657212 89389" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="x0020_1">
                    <metadata id="x0020Layer" />
                    <path class="fil0"
                        d="M58141 5339l14065 0 -29021 82888 -14280 0 -28905 -82888 13949 0 17280 51543c907,2458 1874,5613 2881,9482 1026,3889 1683,6770 1993,8647 484,-2863 1238,-6191 2263,-9982 1026,-3773 1859,-6559 2498,-8378l17277 -51312z" />
                    <path id="1" class="fil0"
                        d="M361458 88227l-24028 -69228 -465 0c657,10292 967,19930 967,28926l0 40302 -12285 0 0 -82888 19097 0 23024 65939 328 0 23700 -65939 19154 0 0 82888 -13021 0 0 -40998c0,-4123 97,-9480 310,-16097 195,-6617 387,-10624 542,-12017l-465 0 -24880 69112 -11978 0z" />
                    <path id="2" class="fil0"
                        d="M486968 56766c0,10256 -2631,18245 -7874,23992 -5263,5747 -12576,8631 -21940,8631 -5863,0 -11050,-1318 -15537,-3967 -4509,-2650 -7971,-6444 -10390,-11415 -2418,-4953 -3617,-10700 -3617,-17241 0,-10176 2610,-18108 7815,-23818 5224,-5707 12577,-8570 22057,-8570 9075,0 16252,2921 21555,8765 5281,5844 7931,13718 7931,23623zm-45641 0c0,14472 5340,21710 16039,21710 10603,0 15884,-7238 15884,-21710 0,-14317 -5321,-21475 -15981,-21475 -5610,0 -9656,1856 -12169,5552 -2516,3696 -3773,9017 -3773,15923z" />
                    <path id="3" class="fil0"
                        d="M525605 78592c3250,0 6502,-503 9751,-1530l0 10060c-1470,639 -3386,1181 -5707,1607 -2322,447 -4722,660 -7217,660 -12613,0 -18923,-6657 -18923,-19970l0 -33781 -8570 0 0 -5900 9191 -4876 4527 -13253 8223 0 0 13931 17858 0 0 10098 -17858 0 0 33550c0,3212 794,5573 2398,7101 1606,1527 3717,2303 6327,2303z" />
                    <path id="4" class="fil0"
                        d="M603500 56766c0,10256 -2631,18245 -7873,23992 -5263,5747 -12577,8631 -21941,8631 -5863,0 -11050,-1318 -15537,-3967 -4509,-2650 -7971,-6444 -10390,-11415 -2418,-4953 -3617,-10700 -3617,-17241 0,-10176 2610,-18108 7816,-23818 5223,-5707 12576,-8570 22056,-8570 9075,0 16252,2921 21555,8765 5281,5844 7931,13718 7931,23623zm-45640 0c0,14472 5339,21710 16039,21710 10602,0 15884,-7238 15884,-21710 0,-14317 -5321,-21475 -15982,-21475 -5610,0 -9656,1856 -12169,5552 -2516,3696 -3772,9017 -3772,15923z" />
                    <path id="5" class="fil0"
                        d="M650574 24378c2689,0 4895,195 6635,563l-1314 12419c-1877,-445 -3852,-676 -5884,-676 -5318,0 -9635,1740 -12942,5205 -3310,3481 -4953,8011 -4953,13563l0 32775 -13331 0 0 -62687 10429 0 1761 11028 676 0c2071,-3735 4798,-6714 8126,-8901 3346,-2185 6945,-3289 10797,-3289z" />
                    <path id="6" class="fil0"
                        d="M95057 88227l-13313 0 0 -62687 13313 0 0 62687zm-14105 -79289c0,-2379 637,-4216 1954,-5494 1296,-1296 3154,-1935 5570,-1935 2343,0 4180,639 5476,1935 1296,1278 1956,3115 1956,5494 0,2264 -660,4044 -1956,5361 -1296,1296 -3133,1953 -5476,1953 -2416,0 -4274,-657 -5570,-1953 -1317,-1317 -1954,-3097 -1954,-5361z" />
                    <path id="7" class="fil0"
                        d="M135242 89389c-7816,0 -13930,-2845 -18302,-8516 -4393,-5667 -6578,-13620 -6578,-23875 0,-10293 2224,-18303 6654,-24029 4432,-5728 10584,-8591 18439,-8591 8242,0 14512,3039 18826,9133l678 0c-639,-4490 -967,-8050 -967,-10660l0 -22851 13386 0 0 88227 -10426 0 -2324 -8203 -636 0c-4259,6249 -10508,9365 -18750,9365zm3560 -10797c5494,0 9480,-1549 11959,-4625 2495,-3076 3791,-8068 3867,-14995l0 -1856c0,-7895 -1296,-13505 -3867,-16833 -2556,-3328 -6580,-4992 -12057,-4992 -4700,0 -8299,1895 -10833,5689 -2535,3812 -3791,9209 -3791,16252 0,6945 1217,12245 3675,15884 2458,3656 6133,5476 11047,5476z" />
                    <path id="8" class="fil0"
                        d="M213794 89389c-9750,0 -17374,-2845 -22869,-8534 -5494,-5689 -8241,-13544 -8241,-23526 0,-10237 2552,-18305 7642,-24168 5108,-5862 12111,-8783 21031,-8783 8260,0 14801,2516 19599,7545 4798,5032 7198,11959 7198,20782l0 7195 -41753 0c174,6075 1817,10757 4914,14028 3115,3270 7469,4895 13097,4895 3696,0 7140,-347 10332,-1044 3194,-696 6638,-1858 10295,-3483l0 10834c-3252,1548 -6541,2652 -9869,3291 -3308,636 -7119,968 -11376,968zm-2437 -54911c-4237,0 -7624,1336 -10179,4025 -2553,2690 -4062,6617 -4564,11762l28440 0c-76,-5184 -1314,-9112 -3733,-11783 -2419,-2668 -5747,-4004 -9964,-4004z" />
                    <path id="9" class="fil0"
                        d="M308930 56766c0,10256 -2632,18245 -7874,23992 -5263,5747 -12577,8631 -21941,8631 -5862,0 -11049,-1318 -15537,-3967 -4508,-2650 -7971,-6444 -10389,-11415 -2419,-4953 -3618,-10700 -3618,-17241 0,-10176 2611,-18108 7816,-23818 5224,-5707 12577,-8570 22057,-8570 9075,0 16252,2921 21554,8765 5282,5844 7932,13718 7932,23623zm-45641 0c0,14472 5339,21710 16039,21710 10603,0 15884,-7238 15884,-21710 0,-14317 -5321,-21475 -15981,-21475 -5610,0 -9656,1856 -12169,5552 -2516,3696 -3773,9017 -3773,15923z" />
                </g>
            </svg>
        </div>
        <div class="menu" *ngIf="menu !== undefined">
            <ul>
                <ng-template ngFor let-item [ngForOf]="menu.menuContents">
                    <li *ngIf="item.menu_content_id===null">
                        <a *ngIf="item.url" [routerLink]="item.url + '.'" class="item" routerLinkActive="active">
                            <span class="name">{{ item.name }}</span>
                        </a>
                        <span *ngIf="!item.url" class="item">
                            <span class="name">{{ item.name }}</span>
                        </span>
                        <ul *ngIf="item.menuContents.length > 0">
                            <li *ngFor="let sub of item.menuContents">
                                <a *ngIf="sub.url" [routerLink]="sub.url + '.'" class="item" routerLinkActive="active">
                                    <span *ngIf="sub.image" class="image"><img [src]="cdnUrl + sub.image"></span>
                                    <span class="name">{{ sub.name }}</span>
                                </a>
                                <span *ngIf="!sub.url" class="item">
                                    <span *ngIf="sub.image" class="image"><img [src]="cdnUrl + sub.image"></span>
                                <span class="name">{{ sub.name }}</span>
                                </span>
                            </li>
                        </ul>
                    </li>
                </ng-template>
            </ul>
        </div>
        <div class="favorite">
            <a [routerLink]="['/personal/.']" [queryParams]="{s: 'favorite'}">
                <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="27px" height="23px" version="1.0" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                    viewBox="0 0 17013 14802" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Слой_x0020_1">
                        <metadata id="CorelCorpID_0Corel-Layer" />
                        <path class="favorite-icon"
                            d="M14325 863c-665,-366 -1427,-577 -2243,-577 -1439,0 -2722,654 -3572,1683 -856,-1029 -2141,-1683 -3582,-1683 -810,0 -1572,211 -2240,577 -1433,794 -2402,2317 -2402,4071 0,500 82,980 230,1429 796,3585 7994,8102 7994,8102 0,0 7188,-4517 7986,-8102 148,-449 231,-929 231,-1429 0,-1754 -969,-3274 -2402,-4071z" />
                    </g>
                </svg>
                <span class="baloon">Избранное</span>
            </a>
        </div>
        <div class="langs" *ngIf="langList !== undefined">
            <!-- && langList.menuContents.count > 1 -->
            <ul>
                <ng-template ngFor let-item let-i="index" [ngForOf]="langList.menuContents">
                    <li>
                        <a *ngIf="i>0" [href]="item.url">
                            <img [src]="cdnUrl+item.image"><span>{{ item.name }}</span>
                        </a>
                        <span *ngIf="i===0">{{ item.name }}</span>
                    </li>
                </ng-template>
            </ul>
        </div>
        <div class="create" *ngIf="createVideoPage!==undefined">
            <a [routerLink]="createVideoPage.fullUrl+'.'">Создать видео</a>
        </div>
    </section>
</header>