<div class="track-line" *ngIf="track !== undefined">
    <div class="cover">
        <img [src]="cdnUrl + (track.cover !== '' ? track.cover : '/music/covers/nocover.png')" alt="">
    </div>
    <div class="artist">
        <div class="name">
            {{track.name}}
        </div>
        <div class="musician">
            {{track.musician}}
        </div>
    </div>
    <div class="player">
        <button class="play" (click)="candidateAudio.paused || candidateAudio.ended ? candidateAudio.play() : candidateAudio.pause()"><ng-template [ngIf]="candidateAudio.paused || candidateAudio.ended"><svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="12px" height="12px" version="1.1"
                    style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                    viewBox="0 0 2641 3118" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="x0020_1">
                        <path class="fil0" style="fill:#FEFEFE"
                            d="M2499 1338l-2075 -1307c-61,-37 -138,-31 -195,-31 -226,0 -229,172 -229,221l0 2 0 2672 0 2c0,42 3,220 229,220 57,0 134,6 195,-30l2075 -1307c170,-102 140,-221 140,-221 0,0 30,-120 -140,-221z" />
                    </g>
                </svg></ng-template><ng-template [ngIf]="!candidateAudio.paused && !candidateAudio.ended"><svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="10px" height="14px" version="1.1"
                    style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                    viewBox="0 0 1968 2923" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="x0020_2">
                        <rect class="fil0" style="fill:#FEFEFE" width="738" height="2923" />
                        <rect class="fil0" style="fill:#FEFEFE" x="1230" width="738" height="2923" />
                    </g>
                </svg></ng-template></button>
        <div class="full" (mousedown)="playProgressChange($event)" #full>
            <div class="progress" [style.width]="(candidateAudio.currentTime * 100 / candidateAudio.duration) + '%'" #progress></div>
        </div>
        <audio [src]="cdnUrl + track.src" #candidateAudio (timeupdate)="audioTimeupdate()"></audio>
    </div>
    <div class="timer">
        {{startPoint * 1000 | date:'mm:ss'}}&nbsp;-&nbsp;{{(startPoint + track.duration) * 1000 | date:'mm:ss'}}
    </div>
    <div class="button">
        <button (click)="selectTrack()">Выбрать</button>
    </div>
</div>