import { Component, OnInit } from '@angular/core';
import { Page } from '../_models/page';
import { environment } from 'src/environments/environment';
import { ModalService } from '../_services/modal.service';
import { DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';
import { VideoSampleService } from '../_services/video-sample.service';
import { VideoSample } from '../_models/video-sample';
import { VideoCreationProcess } from '../_models/video-creation-process';
import { VideoCreationProcessService } from '../_services/video-creation-process.service';
import { ApiService } from '../_services/api.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    cdnUrl = environment.cdnUrl;
    safeHeader1: SafeHtml;
    safeHeader2: SafeHtml;
    safeHeader3: SafeHtml;
    safeHeader4: SafeHtml;
    safeHeader5: SafeHtml;
    safeHeader6: SafeHtml;
    safeText2: SafeHtml;
    videoSampleList: VideoSample[] = [];
    videoSampleCount = 6;
    imgAr1 = this.cdnUrl + '/images/ar1.png';
    videoCreationProcessList: VideoCreationProcess[] = [];
    currentProcessItem = 0;
    videoCreationTools: Page[] = [];
    popupVideo: SafeResourceUrl = '';

    constructor(
        public page: Page,
        private modalService: ModalService,
        private _sanitizer: DomSanitizer,
        private videoSampleService: VideoSampleService,
        private videoCreationProcessService: VideoCreationProcessService,
        private apiService: ApiService,
        private router: Router
    ) { }

    ngOnInit() {
        if (this.page.optionValue('header1') !== '') {
            this.safeHeader1 = this._sanitizer.bypassSecurityTrustHtml(this.page.optionValue('header1').toString());
        }
        if (this.page.optionValue('header2') !== '') {
            this.safeHeader2 = this.sanHtml(this.page.optionValue('header2'));
        }
        if (this.page.optionValue('header3') !== '') {
            this.safeHeader3 = this.sanHtml(this.page.optionValue('header3'));
        }
        if (this.page.optionValue('header4') !== '') {
            this.safeHeader4 = this.sanHtml(this.page.optionValue('header4'));
        }
        if (this.page.optionValue('header6') !== '') {
            this.safeHeader5 = this.sanHtml(this.page.optionValue('header6'));
        }
        if (this.page.optionValue('header8') !== '') {
            this.safeHeader6 = this.sanHtml(this.page.optionValue('header8'));
        }
        if (this.page.optionValue('text2') !== '') {
            this.safeText2 = this.sanHtml(this.page.optionValue('text2'));
        }

        this.videoSampleService.listOnMain().subscribe(list => {
            list.every(v => {
                this.videoSampleList.push(new VideoSample(v));
                if (this.videoSampleList.length === this.videoSampleCount) {
                    return false;
                }
                return true;
            });
        });

        this.videoCreationProcessService.list().subscribe(list => {
            list.every(v => {
                this.videoCreationProcessList.push(new VideoCreationProcess(v));
                return true;
            });
        });

        this.apiService.pages(4).subscribe(list => {
            list.every(v => {
                this.videoCreationTools.push(new Page(v));
                return true;
            });
        });
    }

    openModal(id: string) {
        this.modalService.open(id);
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    createVideo(id: number) {
        this.router.navigate([`/${environment.teamplateApp}/.`], { queryParams: {template_id: id }});
    }

    sanHtml(text: string): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(text.replace('\n', '<br>'));
    }

    sanVideo(url: string): SafeResourceUrl {
        return this._sanitizer.bypassSecurityTrustResourceUrl(url.indexOf('youtube.com') > -1 || url.indexOf('youtu.be') > -1 ? 'https://www.youtube.com/embed/' + url.replace(/.+(\?v=|youtu.be\/)/, '') + '?controls=0&autoplay=1' : this.cdnUrl + url);
    }

    openVideo(url: string) {
        this.popupVideo = this.sanVideo(url);
        this.openModal('popup-video');
    }

    closeVideo() {
        this.closeModal('popup-video');
        this.popupVideo = '';
    }

}
