import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Page } from '../_models/page';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  articleList: Page[];
  currentPage = 1;
  pageSize = 9;
  cdnUrl = environment.cdnUrl;
  totalCount = 0;
  moreLoading = false;
  pageCount: number;

  constructor(
    public page: Page,
    private _sanitizer: DomSanitizer,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // let params: any = { page: this.currentPage, page_size: this.pageSize };
    // if (this.page.page_type_id === 8) {
    //   params.article_section = this.page.id;
    // }
    // this.apiService.custom('data', 'articles', params, 'post').subscribe(list => {
    //   if (list) {
    //     this.articleList = [];
    //     list.every(v => {
    //       this.articleList.push(new Page(v));
    //       return true;
    //     });
    //   }
    // });
    // this.apiService.custom('data', 'article-count', params, 'post').subscribe(count => {
    //   this.totalCount = count;
    //   this.pageCount = Math.floor(this.totalCount / this.pageSize);
    // });
    this.activatedRoute.queryParams.subscribe(par => {
      if (par['page'] !== undefined) {
        this.currentPage = +par['page'];
      } else {
        this.currentPage = 1;
      }
      let params: any = { page: this.currentPage, page_size: this.pageSize };
      if (this.page.page_type_id === 8) {
        params.article_section = this.page.id;
      }
      this.apiService.custom('data', 'articles', params, 'post').subscribe((list: any[]) => {
        if (list) {
          this.articleList = [];
          list.every((v: any) => {
            this.articleList.push(new Page(v));
            return true;
          });
        }
      });
      this.apiService.custom('data', 'article-count', params, 'post').subscribe((count: number) => {
        this.totalCount = count;
        this.pageCount = Math.ceil(this.totalCount / this.pageSize);
      });
    });
  }

  sanHtml(text: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(text !== null ? text.replace('\n', '<br>') : '');
  }

  sanText(text: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(text);
  }

  showAll() {
    this.moreLoading = true;
    let params: any = { page: 1, page_size: this.totalCount };
    if (this.page.page_type_id === 8) {
      params.article_section = this.page.id;
    }
    this.apiService.custom('data', 'articles', params, 'post').subscribe((list: any[]) => {
      if (list) {
        this.pageSize = this.totalCount;
        this.pageCount = 1;
        this.articleList = [];
        list.every(v => {
          this.articleList.push(new Page(v));
          return true;
        });
      }
      this.moreLoading = false;
    });
  }

}
