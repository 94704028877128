<header>
    <div class="wrapper">
        <div class="logo">
            <img [src]="cdnUrl + img1" alt="">
        </div>
        <div class="menu" *ngIf="menu !== undefined">
            <ul>
                <ng-template ngFor let-item [ngForOf]="menu.menuContents">
                    <li *ngIf="item.menu_content_id===null">
                        <a *ngIf="item.url" [routerLink]="item.url + '.'" class="item" routerLinkActive="active">
                            <span class="name">{{ item.name }}</span>
                        </a>
                        <span *ngIf="!item.url" class="item">
                            <span class="name">{{ item.name }}</span>
                        </span>
                        <ul *ngIf="item.menuContents.length > 0">
                            <li *ngFor="let sub of item.menuContents">
                                <a *ngIf="sub.url" [routerLink]="sub.url + '.'" class="item" routerLinkActive="active">
                                    <span *ngIf="sub.image" class="image"><img [src]="cdnUrl + sub.image"></span>
                                    <span class="name">{{ sub.name }}</span>
                                </a>
                                <span *ngIf="!sub.url" class="item">
                                    <span *ngIf="sub.image" class="image"><img [src]="cdnUrl + sub.image"></span>
                                <span class="name">{{ sub.name }}</span>
                                </span>
                            </li>
                        </ul>
                    </li>
                </ng-template>
            </ul>
        </div>
        <ul class="steps">
            <li>
                <span class="h">Выбор шаблона</span>
                <span class="s">1 шаг</span>
            </li>
            <li class="active">
                <span class="h">Создание видео</span>
                <span class="s">2 шаг</span>
            </li>
            <li>
                <span class="h">Готовое видео</span>
                <span class="s">3 шаг</span>
            </li>
        </ul>
        <a class="settings"><img [src]="cdnUrl + img2" alt=""> Настройки</a>
        <div class="langs" *ngIf="langList !== undefined">
            <!-- && langList.menuContents.count > 1 -->
            <ul>
                <ng-template ngFor let-item let-i="index" [ngForOf]="langList.menuContents">
                    <li>
                        <a *ngIf="i>0" [href]="item.url">
                            <img [src]="cdnUrl+item.image"><span>{{ item.name }}</span>
                        </a>
                        <span *ngIf="i===0">{{ item.name }}</span>
                    </li>
                </ng-template>
            </ul>
        </div>
        <div class="save">
            <a (click)="saveProject()">Сохранить проект</a>
        </div>
    </div>
</header>