<app-header></app-header>

<section class="section-1">
    <div class="wrapper">
        <app-breadcrumbs></app-breadcrumbs>
        <div class="page-name" [innerHTML]="sanHtml(page.optionValue('header18'))"></div>
    </div>
</section>

<section class="section-2">
    <div class="wrapper">
        <div class="left">
            <ul class="article-list" *ngIf="articleList !== undefined">
                <li *ngFor="let item of articleList">
                    <a [routerLink]="[item.fullUrl + '.']">
                        <span class="image">
                            <img [src]="cdnUrl + item.optionValue('image6')" alt="">
                            <span class="check-video" *ngIf="item.optionValue('check1') === '1'">
                                <img [src]="cdnUrl + '/images/art-video.png'" alt="">
                            </span>
                        <span class="check-text" *ngIf="item.optionValue('check1') !== '1'">
                                <img [src]="cdnUrl + '/images/art-text.png'" alt="">
                            </span>
                        <span class="views" *ngIf="+item.optionValue('number1') > 0">
                                <span class="icon">
                                    <img [src]="cdnUrl + '/images/art-views.png'" alt="">
                                </span>
                        <span class="num">{{item.optionValue('number1')}}</span>
                        </span>
                        </span>
                        <span class="name">{{item.name}}</span>
                    </a>
                </li>
            </ul>

            <div class="showall" *ngIf="totalCount !== undefined && totalCount > pageSize">
                <button (click)="showAll()" *ngIf="!moreLoading">Показать все статьи</button>
                <div class="loading" *ngIf="moreLoading"></div>
            </div>

            <div class="pagination" *ngIf="pageCount > 1">
                <ul>
                    <li class="prev">
                        <a [routerLink]="['./']" [queryParams]="{page: currentPage - 1 > 1 ? (currentPage - 1) : null}" *ngIf="currentPage > 1"><svg
                                xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="7px" height="13px"
                                version="1.1"
                                style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                viewBox="0 0 516 955" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <defs>
                                    <style type="text/css">
                                        .fil0 {
                                            fill: #333333
                                        }
                                    </style>
                                </defs>
                                <g id="Слой_x0020_1">
                                    <metadata id="CorelCorpID_0Corel-Layer" />
                                    <path class="fil0"
                                        d="M93 477l412 -411c15,-15 15,-40 0,-55l0 0c-16,-15 -40,-15 -55,0l-439 439c-6,6 -10,15 -11,23l0 0 0 2 0 0 0 1 0 0 0 1 0 0 0 0 0 1 0 0 0 1 0 0 0 1 0 0 0 2 0 0c1,8 5,17 11,23l439 439c15,15 39,15 55,0l0 0c15,-15 15,-40 0,-55l-412 -412z" />
                                </g>
                            </svg> <span>Предыдущие</span></a>
                        <span class="disabled" *ngIf="currentPage === 1"><svg
                                xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="7px" height="13px"
                                version="1.1"
                                style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                viewBox="0 0 516 955" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <defs>
                                    <style type="text/css">
                                        .fil2 {
                                            fill: grey;
                                        }
                                    </style>
                                </defs>
                                <g id="Слой_x0020_1">
                                    <metadata id="CorelCorpID_0Corel-Layer" />
                                    <path class="fil2"
                                        d="M93 477l412 -411c15,-15 15,-40 0,-55l0 0c-16,-15 -40,-15 -55,0l-439 439c-6,6 -10,15 -11,23l0 0 0 2 0 0 0 1 0 0 0 1 0 0 0 0 0 1 0 0 0 1 0 0 0 1 0 0 0 2 0 0c1,8 5,17 11,23l439 439c15,15 39,15 55,0l0 0c15,-15 15,-40 0,-55l-412 -412z" />
                                </g>
                            </svg> <span>Предыдущие</span></span>
                    </li>
                    <li *ngFor="let e of [].constructor(pageCount); index as i">
                        <span class="current" *ngIf="(i + 1) === currentPage"><span>{{i + 1}}</span></span>
                        <span class="ellipsis" *ngIf="(i + 1) === 2 && (i + 1) < (currentPage - 2) || (i + 1) === (pageCount - 1) && (i + 1) > (currentPage + 2)">. . .</span>
                        <a [routerLink]="['./']" [queryParams]="{page: i > 0 ? (i + 1) : null}" *ngIf="(i + 1 !== currentPage) && (i === 0 || i + 1 > currentPage - 3 || i + 1 < currentPage + 3 || i + 1 === pageCount)"><span>{{i + 1}}</span></a>
                    </li>
                    <li class="next">
                        <a [routerLink]="['./']" [queryParams]="{page: (currentPage + 1)}" *ngIf="currentPage < pageCount"><span>Следующие</span> <svg
                                xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="7px" height="13px"
                                version="1.1"
                                style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                viewBox="0 0 1083 2006" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <defs>
                                    <style type="text/css">
                                        .fil1 {
                                            fill: #333333
                                        }
                                    </style>
                                </defs>
                                <g id="Слой_x0020_1">
                                    <metadata id="CorelCorpID_0Corel-Layer" />
                                    <path class="fil1"
                                        d="M888 1003l-864 -864c-32,-32 -32,-83 0,-115l0 0c32,-32 83,-32 115,0l921 921c13,13 21,31 23,49l0 0 0 4 0 0 0 2 0 0 0 2 0 0 0 1 0 1 0 0 0 2 0 0 0 2 0 0 0 3 0 1c-2,18 -10,35 -23,49l-921 921c-32,31 -83,31 -115,0l0 0c-32,-32 -32,-84 0,-115l864 -864z" />
                                </g>
                            </svg></a>
                        <span *ngIf="currentPage === pageCount" class="disabled"><span>Следующие</span> <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="7px" height="13px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                            viewBox="0 0 1083 2006" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <defs>
                                    <style type="text/css">
                                        .fil3 {
                                            fill: grey;
                                        }
                                    </style>
                                </defs>
                                <g id="Слой_x0020_1">
                                    <metadata id="CorelCorpID_0Corel-Layer" />
                                    <path class="fil3"
                                        d="M888 1003l-864 -864c-32,-32 -32,-83 0,-115l0 0c32,-32 83,-32 115,0l921 921c13,13 21,31 23,49l0 0 0 4 0 0 0 2 0 0 0 2 0 0 0 1 0 1 0 0 0 2 0 0 0 2 0 0 0 3 0 1c-2,18 -10,35 -23,49l-921 921c-32,31 -83,31 -115,0l0 0c-32,-32 -32,-84 0,-115l864 -864z" />
                                </g>
                            </svg></span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="right">
            <app-article-section-list [currentArticleSectionId]="page.page_type_id === 8 ? page.id : undefined"></app-article-section-list>
        </div>
    </div>
</section>

<section class="section-3">
    <div class="wrapper page-text">
        <h1 [innerHTML]="sanHtml(page.pageSeo.h1)"></h1>
        <div class="" [innerHTML]="sanText(page.pageSeo.content)"></div>
    </div>
</section>

<app-footer></app-footer>