export class OptionType {

    id: number;
    name: string;
    field: string;

    constructor(obj: any) {
        this.id = obj.id;
        this.name = obj.name;
        this.field = obj.field;
    }
}
