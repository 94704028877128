export class MenuContentLanguage {

    public id: number;
    public menu_content_id: number;
    public language_id: number;
    public name: string;
    public url: string;

    constructor(obj: any) {
        this.id = obj.id;
        this.menu_content_id = obj.menu_content_id;
        this.language_id = obj.language_id;
        this.name = obj.name;
        this.url = obj.url;
    }
}
