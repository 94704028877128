import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VideoTemplate } from 'src/app/_models/video-template';

@Component({
  selector: 'app-video-template-workplace',
  templateUrl: './video-template-workplace.component.html',
  styleUrls: ['./video-template-workplace.component.scss']
})
export class VideoTemplateWorkplaceComponent implements OnInit {

  @Input() videoTemplate: VideoTemplate;
  @Input() workStatus: string;
  @Output() workStatusChange = new EventEmitter<string>();
  /**
   * Длина созданного отрезка (в секундах)
   */
  producedDuration = 0;
  /**
   * Флаг выбранного элемента
   * @todo После завершения верстки поставить в false
   */
  selectedItem = true;

  constructor() { }

  ngOnInit(): void {
  }

  sendWorkStatus(m: string) {
    this.workStatus = m;
    this.workStatusChange.emit(this.workStatus);
  }

}
