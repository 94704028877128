import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Page } from '../_models/page';
import { VideoTemplate } from '../_models/video-template';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {

  cdnUrl = environment.cdnUrl;
  header1: SafeHtml;
  header2: SafeHtml;
  header3: SafeHtml;
  text1: SafeHtml;
  text2: SafeHtml;
  text3: SafeHtml;
  text4: SafeHtml;
  back1: string;
  templateList: VideoTemplate[] = [];

  constructor(
    public page: Page,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.header1 = this.sanHtml(this.page.optionValue('header19') || '');
    this.header2 = this.sanHtml(this.page.optionValue('text19') || '');
    this.header3 = this.sanHtml(this.page.optionValue('header20') || '');
    this.text1 = this.sanHtml(this.page.optionValue('text20') || '');
    this.text2 = this.sanHtml(this.page.optionValue('text21') || '');
    this.text3 = this.sanHtml(this.page.optionValue('text22') || '');
    this.text4 = this.sanHtml(this.page.optionValue('text23') || '');
    this.back1 = this.page.optionValue('image7') || '';
    const templateIds = this.page.optionValue('video-template').split(',');
    if (templateIds.length > 0) {
      templateIds.every(v => {
        this.apiService.getVideoTemplate(+v).subscribe(element => {
          this.templateList.push(new VideoTemplate(element));
        });
        return true;
      });
    }
  }

  sanHtml(text: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(text !== null ? text.replace('\n', '<br>') : '');
  }

  sanText(text: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(text);
  }

}
