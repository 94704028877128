export class MusicElement {

    public id: number;
    public musician: string;
    public name: string;
    public src: string;
    public cover: string;
    public duration: number;

    constructor(obj: any) {
        this.id = obj.id;
        this.musician = obj.musician;
        this.name = obj.name;
        this.src = obj.src;
        this.cover = obj.cover;
        this.duration = obj.duration;
    }

}
