import { Component, OnInit, Injector } from '@angular/core';
import { Page } from '../_models/page';
import { ApiService } from '../_services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { PageTextComponent } from '../page-text/page-text.component';
import { NotFoundComponent } from '../not-found/not-found.component';
import { CatalogComponent } from '../catalog/catalog.component';
import { BlogComponent } from '../blog/blog.component';
import { ArticleComponent } from '../article/article.component';
import { StructuralComponent } from '../structural/structural.component';
import { ToolComponent } from '../tool/tool.component';
import { CreateFromTemplateComponent } from '../create-from-template/create-from-template.component';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

    templateComponent: any;
    page: Page;
    pageInjector: Injector;

    constructor(
        private apiService: ApiService,
        private route: ActivatedRoute,
        private router: Router,
        private injector: Injector
    ) { }

    ngOnInit() {
        //        this.urlSubject = new BehaviorSubject<string>(this.router.url);
        this.route.url.pipe()
            .subscribe(url => {
                this.templateComponent = null;
                this.apiService.pageByUrl(this.router.url.replace('.', ''))
                    .subscribe(p => {
                        if (p) {
                            this.page = new Page(p);
                            this.pageInjector = Injector.create({ providers: [{ provide: Page, useValue: this.page }], parent: this.injector });
                            const templatePath = p.template ? p.template.path_front : p.pageType.template.path_front;
                            switch (templatePath) {
                                //                                case 'search':
                                //                                    this.templateComponent = SearchResultComponent;
                                //                                    break;
                                case 'home':
                                    this.templateComponent = HomeComponent;
                                    break;
                                //                                case 'sitemap':
                                //                                    this.templateComponent = SitemapComponent;
                                //                                    break;
                                //                                case 'page-order':
                                //                                    this.templateComponent = PageOrderComponent;
                                //                                    break;
                                case 'catalog':
                                    this.templateComponent = CatalogComponent;
                                    break;
                                case 'blog':
                                    this.templateComponent = BlogComponent;
                                    break;
                                case 'article':
                                    this.templateComponent = ArticleComponent;
                                    break;
                                case 'structural':
                                    this.templateComponent = StructuralComponent;
                                    break;
                                case 'tool':
                                    this.templateComponent = ToolComponent;
                                    break;
                                case 'create-from-template':
                                    this.templateComponent = CreateFromTemplateComponent;
                                    break;
                                default:
                                    this.templateComponent = PageTextComponent;
                                    break;
                            }
                        } else {
                            this.templateComponent = NotFoundComponent;
                        }
                    });
            });
    }

}
