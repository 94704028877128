<ul class="template-list" *ngIf="templateList">
    <li *ngFor="let item of templateList">
        <span class="video">
            <a (click)="openVideo(item.videoSample.video)">
                <img [src]="cdnUrl + item.videoSample.cover">
            </a>
        </span>
        <span class="counts" *ngIf="item.videoProperty.image_count > 0 || item.videoProperty.video_count > 0">
            <span *ngIf="item.videoProperty.image_count > 0" class="photos">
                <img [src]="cdnUrl + '/images/icotphoto.svg'" alt="">
                <span>{{item.videoProperty.image_count}} {{item.videoProperty.image_count | i18nPlural:{'one': '
                    фотография', 'few':' фотографии', 'many':' фотографий', 'other':' фотографий'}:'ru-RU' }}</span>
        </span>
        <span *ngIf="item.videoProperty.video_count > 0" class="videos">
                <img [src]="cdnUrl + '/images/icotvideo.svg'" alt="">
                <span>{{item.videoProperty.video_count}} видео</span>
        </span>
        </span>
        <span class="name">
            {{item.videoSample.name}}
        </span>
        <span class="link">
            <button (click)="createVideo(item.id)" class="create">Создать видео</button>
            <button (click)="favoriteChange(item.id)" class="favorite">
                <span class="inactive"><svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="33px"
                        height="29px" version="1.1"
                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                        viewBox="0 0 157309 136605" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <defs>
                            <style type="text/css">
                                .str0 {
                                    stroke: #9D9E9E;
                                    stroke-width: 4125.61
                                }

                                .fil0 {
                                    fill: none
                                }
                            </style>
                        </defs>
                        <g id="Слой_x0020_1">
                            <metadata id="CorelCorpID_0Corel-Layer" />
                            <path class="fil0 str0"
                                d="M132870 7439c-6200,-3416 -13298,-5376 -20899,-5376 -13400,0 -25366,6096 -33287,15685 -7975,-9589 -19946,-15685 -33367,-15685 -7548,0 -14646,1960 -20872,5376 -13351,7390 -22382,21592 -22382,37926 0,4667 769,9140 2144,13325 7417,33394 74477,75479 74477,75479 0,0 66979,-42085 74413,-75479 1375,-4185 2149,-8658 2149,-13325 0,-16334 -9031,-30508 -22376,-37926z" />
                        </g>
                    </svg></span>
        <span class="active"><svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="33px"
                        height="29px" version="1.1"
                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                        viewBox="0 0 13550 11686" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <defs>
                            <style type="text/css">
                                .fil1 {
                                    fill: #E64646
                                }
                            </style>
                        </defs>
                        <g id="Слой_x0020_1">
                            <metadata id="CorelCorpID_0Corel-Layer" />
                            <path class="fil1"
                                d="M11571 476c-548,-303 -1176,-476 -1849,-476 -1185,0 -2244,539 -2944,1387 -706,-848 -1765,-1387 -2952,-1387 -667,0 -1295,173 -1846,476 -1181,653 -1980,1910 -1980,3354 0,413 68,809 190,1179 656,2954 6588,6677 6588,6677 0,0 5925,-3723 6582,-6677 122,-370 190,-766 190,-1179 0,-1444 -799,-2698 -1979,-3354z" />
                        </g>
                    </svg></span>
        <span class="text">Избранное</span>
        </button>
        </span>
    </li>
</ul>

<div class="showmore" *ngIf="page < templatePageCount">
    <button class="" (click)="loadMore()" *ngIf="moreLoading === false">Посмотреть ещё {{templatePageSize}}</button>
    <div class="loading" *ngIf="moreLoading"></div>
</div>

<div class="pagination" *ngIf="templatePageCount > 1">
    <ul>
        <li *ngIf="page > 1">
            <a [routerLink]="['./']"><svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="8px" height="8px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                viewBox="0 0 1901 2041"
                 xmlns:xlink="http://www.w3.org/1999/xlink">
                 <defs>
                  <style type="text/css">
                    .fil2 {fill:#434242}
                  </style>
                 </defs>
                 <g id="Слой_x0020_1">
                  <metadata id="CorelCorpID_0Corel-Layer"/>
                  <path class="fil2" d="M33 939l906 -905c44,-45 117,-45 162,0l0 0c44,44 44,117 0,162l-825 824 825 825c44,45 44,118 0,162l0 0c-45,45 -118,45 -162,0l-906 -905c-19,-20 -30,-45 -32,-71l-1 -2 0 -1 0 -1 0 -2 0 -1 0 -2 0 -1 0 -1 0 -2 0 -1 0 -2 0 -1 0 -1 0 -1 1 -2c2,-26 13,-52 32,-71z"/>
                  <path class="fil2" d="M800 939l905 -905c45,-45 118,-45 162,0l0 0c45,44 45,117 0,162l-824 824 824 825c45,45 45,118 0,162l0 0c-44,45 -117,45 -162,0l-905 -905c-20,-20 -31,-45 -33,-71l0 -2 0 -1 0 -1 0 -2 0 -1 0 -2 0 -1 0 -1 0 -2 0 -1 0 -2 0 -1 0 -1 0 -1 0 -2c3,-26 13,-52 33,-71z"/>
                 </g>
                </svg></a>
        </li>
        <li *ngIf="page > 1">
            <a [routerLink]="['./']" [queryParams]="{page: (page - 1)}"><svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="5px" height="8px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                    viewBox="0 0 1054 1896"
                     xmlns:xlink="http://www.w3.org/1999/xlink">
                     <defs>
                      <style type="text/css">
                        .fil3 {fill:#434242}
                      </style>
                     </defs>
                     <g id="Слой_x0020_1">
                      <metadata id="CorelCorpID_0Corel-Layer"/>
                      <path class="fil3" d="M31 872l841 -841c41,-41 109,-41 150,0l0 0c42,41 42,109 0,151l-766 766 766 766c42,41 42,109 0,150l0 0c-41,42 -109,42 -150,0l-841 -841c-18,-18 -29,-41 -30,-65l-1 -2 0 -1 0 -2 0 -1 0 -1 0 -1 0 -2 0 -1 0 -1 0 -1 0 -2 0 -1 0 -2 0 0 1 -2c2,-24 12,-48 30,-66z"/>
                     </g>
                    </svg></a>
        </li>
        <li *ngFor="let e of [].constructor(templatePageCount); index as i">
            <span class="current" *ngIf="(i + 1) === page">{{i + 1}}</span>
            <a [routerLink]="['./']" [queryParams]="{page: (i + 1)}" *ngIf="i + 1 !== page">{{i + 1}}</a>
        </li>
        <li *ngIf="page < templateTotalCount">
            <a [routerLink]="['./']" [queryParams]="{page: (page + 1)}"><svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="5px" height="8px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                    viewBox="0 0 324 584"
                     xmlns:xlink="http://www.w3.org/1999/xlink">
                     <defs>
                      <style type="text/css">
                        .fil4 {fill:#434242}
                      </style>
                     </defs>
                     <g id="Слой_x0020_1">
                      <metadata id="CorelCorpID_0Corel-Layer"/>
                      <path class="fil4" d="M315 268l-259 -258c-13,-13 -34,-13 -46,0l0 0c-13,12 -13,33 0,46l236 236 -236 236c-13,13 -13,33 0,46l0 0c12,13 33,13 46,0l259 -259c6,-5 9,-13 9,-20l0 -1 0 0 0 0 0 -1 0 0 0 -1 0 0 0 0 0 -1 0 0 0 -1 0 0 0 0 0 -1 0 0c0,-8 -3,-15 -9,-21z"/>
                     </g>
                    </svg></a>
        </li>
        <li *ngIf="page < templateTotalCount">
            <a [routerLink]="['./']" [queryParams]="{page: templateTotalCount}"><svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="8px" height="8px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                viewBox="0 0 763 819"
                 xmlns:xlink="http://www.w3.org/1999/xlink">
                 <defs>
                  <style type="text/css">
                    .fil5 {fill:#434242}
                  </style>
                 </defs>
                 <g id="Слой_x0020_1">
                  <metadata id="CorelCorpID_0Corel-Layer"/>
                  <path class="fil5" d="M749 377l-363 -364c-18,-17 -47,-17 -65,0l0 0c-18,18 -18,48 0,65l331 331 -331 331c-18,18 -18,47 0,65l0 0c18,18 47,18 65,0l363 -363c8,-8 12,-18 13,-28l0 -1 0 -1 0 0 0 -1 1 0 0 -1 0 0 0 -1 0 -1 0 0 -1 -1 0 0 0 -1 0 0 0 -1c-1,-10 -5,-21 -13,-28z"/>
                  <path class="fil5" d="M442 377l-364 -364c-17,-17 -47,-17 -65,0l0 0c-17,18 -17,48 0,65l331 331 -331 331c-17,18 -17,47 0,65l0 0c18,18 48,18 65,0l364 -363c8,-8 12,-18 13,-28l0 -1 0 -1 0 0 0 -1 0 0 0 -1 0 0 0 -1 0 -1 0 0 0 -1 0 0 0 -1 0 0 0 -1c-1,-10 -5,-21 -13,-28z"/>
                 </g>
                </svg></a>
        </li>
    </ul>
</div>

<popup-modal id="popup-video" class="video">
    <a (click)="closeVideo();" class="close">&times;</a>
    <ng-template [ngIf]="popupVideo">
        <iframe [src]="popupVideo" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ng-template>
</popup-modal>