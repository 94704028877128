import {MenuContent} from './menu-content';

export class Menu {

    public id: number;
    public name: string;
    public position: string;
    public menuContents: MenuContent[];

    constructor(menu: any) {
        this.id = menu.id;
        this.name = menu.name;
        this.position = menu.position;
        this.menuContents = [];
        menu.menuContents.every(v => {
            this.menuContents.push(new MenuContent(v));
            return true;
        });
    }

}
