import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Menu } from '../_models/menu';
import { VideoTemplate } from '../_models/video-template';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'app-create-from-template',
  templateUrl: './create-from-template.component.html',
  styleUrls: ['./create-from-template.component.scss']
})
export class CreateFromTemplateComponent implements OnInit {

  cdnUrl = environment.cdnUrl;
  videoTemplate: VideoTemplate;
  templateId: number;
  workStatus = 'W';

  constructor(
    private apiService: ApiService,
    private activateRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.activateRoute.snapshot.queryParams['template_id'] === undefined) {
      this.router.navigate(['/not-found/.']);
    }
    this.templateId = this.activateRoute.snapshot.queryParams['template_id'];
    this.apiService.getVideoTemplate(this.templateId).subscribe(t => {
      if (t === null) {
        this.router.navigate(['/not-found/.']);
      }
      this.videoTemplate = new VideoTemplate(t);
    });
  }

}
