import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VideoSample } from '../_models/video-sample';

@Injectable({
  providedIn: 'root'
})
export class VideoSampleService {

  constructor(
    private http: HttpClient
  ) { }

  listOnMain(): Observable<VideoSample[]> {
    return this.http.get<VideoSample[]>(environment.apiUrl + 'video-sample?filter[top]=1').pipe();
  }

}
