import { Component, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Menu } from '../_models/menu';
import { ApiService } from '../_services/api.service';
import { environment } from 'src/environments/environment';
import { Page } from '../_models/page';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    logo: string;
    menu: Menu;
    langList: Menu;
    cdnUrl = environment.cdnUrl;
    createVideoPage: Page;

    constructor(
        private apiService: ApiService
    ) {
    }

    ngOnInit() {
        this.apiService.siteOption('logo')
            .subscribe((option) => {
                this.logo = option.siteOptionValue.value;
            });

        this.apiService.menu('mainmenu')
            .subscribe((menu) => {
                this.menu = new Menu(menu);
            });

        this.apiService.menu('language')
            .subscribe((menu) => {
                this.langList = new Menu(menu);
            });

        this.apiService.siteOption('createvideopage')
            .subscribe(option => {
                this.apiService.pageById(+option.siteOptionValue.value)
                    .subscribe(page => {
                        if (page) {
                            this.createVideoPage = new Page(page);
                        }
                    });
            });
    }

}
