import { Page } from "./page";
import { VideoProperty } from "./video-property";
import { VideoSample } from "./video-sample";

export class VideoTemplate {
    public id: number;
    public sort: number;
    public videoSample: VideoSample;
    public catalogSections: Page[];
    public catalogCategories: Page[];
    public videoProperty: VideoProperty;

    constructor(obj: any) {
        this.id = obj.id;
        this.sort = obj.sort;
        this.videoSample = new VideoSample(obj.videoSample);
        this.catalogSections = [];
        obj.catalogSections.every((v: any) => {
            this.catalogSections.push(new Page(v));
            return true;
        });
        this.catalogCategories = [];
        obj.catalogCategories.every((v: any) => {
            this.catalogCategories.push(new Page(v));
            return true;
        });
        this.videoProperty = new VideoProperty(obj.videoProperty);
    }
}
