import { Page } from './page';
import { MenuContentLanguage } from './menu-content-language';

export class MenuContent {

    public id: number;
    public menu_id: number;
    public page_id: number;
    public sort: number;
    public menu_content_id: number;
    public image: string;
    public menuContents: MenuContent[];
    public page: Page;
    public menuContentLanguages: MenuContentLanguage[];
    public menuContentLanguage: MenuContentLanguage;

    constructor(menuContent: any) {
        this.id = menuContent.id;
        this.menu_id = menuContent.menu_id;
        this.page_id = menuContent.page_id;
        this.sort = menuContent.sort;
        this.image = menuContent.image;
        this.menu_content_id = menuContent.menu_content_id;
        this.menuContents = [];
        menuContent.menuContents.every(v => {
            this.menuContents.push(new MenuContent(v));
            return true;
        });
        if (menuContent.page !== null) {
            this.page = new Page(menuContent.page);
        } else {
            this.page = null;
        }
        this.menuContentLanguages = [];
        menuContent.menuContentLanguages.every(v => {
            this.menuContentLanguages.push(new MenuContentLanguage(v));
            return true;
        });
        this.menuContentLanguage = new MenuContentLanguage(menuContent.menuContentLanguage);
    }

    get name(): string {
        return this.menuContentLanguage.name;
    }

    get url(): string {
        return this.page ? this.page.fullUrl : this.menuContentLanguage.url;
    }

}
