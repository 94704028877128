<section class="section-1">
    <div class="wrapper">
        <div class="header">
            Выбор музыки
        </div>
        <a (click)="sendWorkStatus('W')" class="close">&times;</a>
    </div>
</section>

<section class="section-2" *ngIf="!browserShow">
    <div class="wrapper">
        <div class="selection-options">
            <div class="option">
                <div class="header">Выберите звуковую дорожку<br>из нашей коллекции</div>
                <div class="buttons">
                    <button class="simple" (click)="fromCollections()">Выбрать из коллекции</button>
                </div>
            </div>
            <div class="option">
                <div class="header">Загрузите звуковую дорожку<br>с компьютера</div>
                <div class="buttons">
                    <button class="simple" (click)="fromFilesystem()">Загрузить с компьютера</button>
                </div>
            </div>
            <div class="option">
                <div class="header">Выберите звуковую дорожку<br>из социальных сетей</div>
                <div class="buttons socials">
                    <button class="social vk" (click)="fromVk()">Из VK</button>
                    <button class="social ok" (click)="fromOk()">Из OK</button>
                    <button class="social fb" (click)="fromFb()">Из Facebook</button>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-3" *ngIf="!browserShow">
    <div class="wrapper">
        <div class="loading-container" *ngIf="loading">
            <div class="header">Загрузка: {{loadedPc}}%</div>
            <div class="line">
                <div class="fill" [style.width]="loadedPc + '%'"></div>
            </div>
        </div>

        <div class="track">
            <div class="full-length">
                <div class="track-length" [style.width]="countTrackLengthPercent() + '%'"><span>{{countTrackLength() *
                        1000 | date:'mm:ss'}}&nbsp;/&nbsp;{{videoTemplate.videoProperty.duration * 1000 |
                        date:'mm:ss'}}</span></div>
            </div>
            <div class="text">{{videoTemplate.videoProperty.duration * 1000 | date:'mm:ss'}}</div>
        </div>

        <div class="onemore" *ngIf="countTrackLengthPercent() > 0 && countTrackLengthPercent() < 100">
            <div class="header">Загрузите ещё одну звуковую дорожку или оставите часть видео без звука</div>
            <div class="buttons">
                <button (click)="fromCollections()">Выбрать ещё 1 дорожку</button>
                <button (click)="repeatTrack()">Проигрывать ещё раз</button>
                <button (click)="sendWorkStatus('W')">Оставить видео без звука</button>
            </div>
        </div>
    </div>
</section>

<section class="section-4" *ngIf="browserShow">
    <div class="wrapper">
        <div class="current-track" *ngIf="candidateTrack !== null">
            <app-video-template-candidate-track [track]="candidateTrack" [startPoint]="0"></app-video-template-candidate-track>
        </div>
    </div>
</section>