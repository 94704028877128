import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Page } from '../_models/page';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'app-catalog-section-list',
  templateUrl: './catalog-section-list.component.html',
  styleUrls: ['./catalog-section-list.component.scss']
})
export class CatalogSectionListComponent implements OnInit {

  @Input() categoryId: number;

  catalogSectionList: Page[] = [];
  pageTypeId = 6;
  cdnUrl = environment.cdnUrl;
  catalogSectionListCount: number[] = [];
  activeId = 0;

  constructor(
    private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.apiService.pages(6)
      .subscribe((list: any[]) => {
        list.every(v => {
          const p = new Page(v);
          if (p.optionValue('top-category') === '1') {
            this.catalogSectionList.push(p);
          }
          return true;
        });

        this.catalogSectionList.every(v => {
          this.apiService.custom('data', 'template-count-by-category', { category_id: v.id }, 'post').subscribe((count: number) => {
            this.catalogSectionListCount[v.id] = count;
          });

          if (this.router.url.indexOf(v.fullUrl) > -1) {
            this.activeId = v.id;
          }
          return true;
        });
      });

  }

}
