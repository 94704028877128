import { OptionType } from './option-type';

export class PageOption {

    id: number;
    name: string;
    code: string;
    default_value: string;
    sort: number;
    optionType?: OptionType;

    constructor(obj: any) {
        this.id = obj.id;
        this.name = obj.name;
        this.code = obj.code;
        this.default_value = obj.default_value;
        this.sort = obj.sort;
        this.optionType = obj.optionType ? new OptionType(obj.optionType) : null;
    }
}
